export default {
  namespaced: true,

  state: {
    appConfig: null,
    activeDecli: null,
    chLanguage: 'de',
  },

  actions: {
    updateAppConfig(context, appConfig) {
      context.commit('UPDATE_APP_CONFIG', appConfig);
    },

    updateActiveDecli(context, activeDecli) {
      context.commit('UPDATE_ACTIVE_DECLI', activeDecli);
    },

    updateChLanguage(context, language) {
      context.commit('UPDATE_CH_LANGUAGE', language);
    },
  },

  mutations: {
    UPDATE_APP_CONFIG(state, appConfig) {
      state.appConfig = appConfig;
    },

    UPDATE_ACTIVE_DECLI(state, activeDecli) {
      state.activeDecli = activeDecli;
    },

    UPDATE_CH_LANGUAGE(state, language) {
      state.chLanguage = language;
    },
  },

  getters: {
    appConfig: (state) => state.appConfig,

    activeDecli: (state) => state.activeDecli,
    activeDecliConfig: (state) => state.activeDecli.config,
    activeDecliContent: (state) => state.activeDecli.content,
    activeDecliRoutes: (state) => state.activeDecli.routes,
    activeDecliTranslations: (state) => state.activeDecli.translations,
  },
};
