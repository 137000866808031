import { render, staticRenderFns } from "./ImagesWrapper.vue?vue&type=template&id=2220408c&scoped=true"
import script from "./ImagesWrapper.vue?vue&type=script&lang=js"
export * from "./ImagesWrapper.vue?vue&type=script&lang=js"
import style0 from "./ImagesWrapper.vue?vue&type=style&index=0&id=2220408c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2220408c",
  null
  
)

export default component.exports