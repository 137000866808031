import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { isDebugMode, isProdEnv, isStagingEnv } from '@/helpers' // OP DATA
import { version, name } from '../package.json'

function getSentryDSN () {
  try {
    const sentryclirc = require('raw-loader!../.sentryclirc').default;
    const [_, dsn] = sentryclirc.match(/\n *dsn *= *(.+)(?: )*/i) || []
    return dsn && dsn.length > 0 ? dsn.trim() : undefined
  } catch (error) {
    return undefined
  }
}

const dsn = getSentryDSN()

// SENTRY
// vuepress@sentry-init
if (!dsn) {
  console.error('DSN Sentry not configured, please accept the merge request')
} else if (!isDebugMode() && (isProdEnv() || isStagingEnv())) {
  let environment = 'development'
  if (isProdEnv()) {
    environment = 'production'
  } else if (isStagingEnv()) {
    environment = 'staging'
  }
  
  Sentry.init({
    environment,
    dsn,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    release: name + '@' + version
  });
}
// vuepress@sentry-init