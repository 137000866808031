<template>
  <button class="viral-link table" @click="emitValue($event)">
    <slot></slot>
  </button>
</template>

<script>
export default {
  methods: {
    emitValue(value) {
      this.$emit("click", value);
    },
  },
};
</script>

<style lang="scss">
svg {
  .shape {
    fill: white;
  }
}
</style>