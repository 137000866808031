import Vue from 'vue';

import store from '@/store';

/**
 * @param {import('vue-router').Route} to 
 */
const getFromId = (to) => {
  if (!isNaN(to.query.idfrom)) {
    return parseInt(to.query.idfrom, 10);
  }

  if (store.getters['user/stateParams'] && store.getters['user/stateParams'].idfrom != null) {
    return parseInt(store.getters['user/stateParams'].idfrom, 10);
  }

  if (store.getters['config/activeDecliConfig'].idfroms.untagged !== null) {
    return parseInt(store.getters['config/activeDecliConfig'].idfroms.untagged, 10);
  }

  return 0;
};

const getIdup = (to) => {
  if (!isNaN(to.query.idup)) {
    return parseInt(to.query.idup, 10);
  }

  if (store.getters['user/stateParams'] && store.getters['user/stateParams'].idup != null) {
    return parseInt(store.getters['user/stateParams'].idup, 10);
  }

  return 0;
};

/**
 * @param {import('vue-router').Route} to 
 */
export default function(to) {
  return Vue.$requestAPI.getSessionToken({
    sRes: `${screen.width}x${screen.height}`,
    iFromId: getFromId(to),
    oUrlParams: to.query,
    iUeId: to.query.idue,
    iParrainId: getIdup(to),
    sReferrer: document.referrer,
  })
}
