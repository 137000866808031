/* eslint-disable */
export default function plugin (Vue, options) {
  if (plugin.installed) { return }

  // CHECK IF APP ID
  if (!options.appIds) {
    throw new Error('[vue-fb] please specify an appId in the options object like so: Vue.use(vueFb, { appIds: { prod: \'YOUR_APP_ID\'} })')
  }

  var host = document.location.hostname
  let appId = null

  if (host.search('numberly.st') > -1) {
    appId = options.appIds.staging
  } else {
    appId = options.appIds.prod
  }

  window.fbAsyncInit = () => {
    window.FB.init({
      appId,
      autoLogAppEvents: true,
      status: true,
      xfbml: true,
      version: 'v2.9'
    })
    Vue.$fb = Vue.prototype.$fb = window.FB
  }

  /* eslint-disable */
  (function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = `//connect.facebook.net/${options.lang}/sdk.js`;
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
  /* eslint-enable */
}
/* eslint-enable */