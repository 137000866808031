/** @type {import("vue/types/options").ComponentOptions} */
const trackedClickMixin = {
  methods: {
    /**
     * Returns a tracked-click URL.
     *
     * @param {number} linkNumber
     * @param {number} [pageID] optional, you can force a specific page-id
     *  instead of relying on the current route `meta.pageId`.
     * @returns {string} tracked-click URL.
     */
    getTrackedClickUrl(linkNumber, pageID) {
      const appConfig = this.$store.getters['config/appConfig'];
      const token = this.$store.getters['global/token'];
      const idu = this.$store.getters['user/idu'];
      const visitId = this.$store.getters['global/visitId'];
      
      const pageId = pageID || this.$route.meta.pageId;
      const iduParam = idu != null ? `&iIdU=${idu}` : '';
      const opName = appConfig.localhostStaticApi ? "numberly_testapi" : appConfig.baseName
      
      const baseUrl = `${document.location.protocol}//${document.location.host}/api/v0/${opName}/${appConfig.activeDecli.apiVersion}/track/page_click`;

      return `${baseUrl}?token=${token}${iduParam}&iPageId=${pageId}&iLinkNumber=${linkNumber}&iVisitId=${visitId}`;
    },
  },
};

export default trackedClickMixin;
