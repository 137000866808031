import footerFooterTermsModal from '../fr/html/footer/footer_terms_modal.html'
import footerFooterChartefbModal from '../fr/html/footer/footer_chartefb_modal.html'
import footerFooterRealisationModal1000mercis from '../fr/html/footer/footer_realisation_modal_1000mercis.html'
import footerFooterRealisationModalNumberly from '../fr/html/footer/footer_realisation_modal_numberly.html'
import footerFooterMentions from '../fr/html/footer/footer_mentions.html'
import footerFooterMentions2 from '../fr/html/footer/footer_mentions2.html'
import footerMentions from '../fr/html/footer/mentions.html'
import modalsShareLinkTitle from '../fr/html/modals/share_link/title.html'

import cookiesBanner from './html/fr/cookies-banner.html';
import cookiesModalContent from './html/fr/cookies-modal-content.html';
import desaboLink from './html/fr/unsubscribe.html';

export default {
  countries: {
    australia: 'Australie',
    austria: 'Autriche',
    belgium: 'Belgique',
    bulgaria: 'Bulgarie',
    canada: 'Canada',
    chile: 'Chili',
    croatia: 'Croatie',
    czech_republic: 'République Tchèque',
    france: 'France',
    germany: 'Allemagne',
    hungary: 'Hongrie',
    iraq: 'Iraq',
    ireland: 'Irlande',
    israel: 'Israel',
    italy: 'Italie',
    jordan: 'Jordanie',
    lebanon: 'Liban',
    mexico: 'Mexique',
    morocco: 'Maroc',
    netherlands: 'Pays-Bas',
    new_zealand: 'Nouvelle-Zélande',
    poland: 'Pologne',
    portugal: 'Portugal',
    romania: 'Roumanie',
    russia: 'Russie',
    saudi_arabia: 'Arabie Saoudite',
    serbie: 'Serbie',
    slovakia: 'Slovaquie',
    south_africa: 'Afrique du Sud',
    spain: 'Espagne',
    switzerland: 'Suisse',
    turkey: 'Turquie',
    uk: 'Royaume-Uni',
    ukraine: 'Ukraine',
    united_arab_emirates: 'Emirats Arabes Unis',
    usa: 'Etats-Unis',
  },

  languages: {
    french: 'Français',
    german: 'Allemand',
  },

  cookies: {
    banner: cookiesBanner,
    modal: {
      content: cookiesModalContent
    }
  },

  desabo: {
    title: "Votre désinscription a bien été prise en compte.",
    button: "Je me réinscris",
    link: desaboLink,
    resub: "Vous êtes bien réinscrit"
  },

  optinisation: {
    title: "Merci!",
    subtitle: "Votre demande a bien été enregistrée, vous recevrez les futures offres de Valenitno Beauty.<br>Si vous le souhaitez, vous pourrez vous désabonner de ces emails à tout moment."
  },

  welcome: {
    select_country: "Veuillez sélectionner votre pays*",
    select_country_placeholder: "Sélectionnez votre pays",
    select_country_error: "Veuillez sélectionner votre pays",
    select_language: "Veuillez sélectionner votre langue*",
    select_language_placeholder: "Sélectionnez votre langue",
    select_language_error: "Veuillez sélectionner votre langue",
    cta_code: "J'ai un code unique",
    cta_no_code: "Je n'ai pas de code unique",
    not_available: "Désolé, mais <b>Valentino Beauty Dreams Club</b> n'est pas disponible dans le pays sélectionné. Nous vous invitons à nous suivre sur Instagram pour découvrir nos actualités sur <b class=\"cursor-pointer\"><a href=\"https://www.instagram.com/valentino.beauty/\" target=\"_blank\">@valentino.beauty</a></b>.",
    go_back: "Je change de pays",
  },

  register: {
    title_with_code: "LE LIEU IDÉAL POUR CRÉER VOTRE PROPRE RÊVE DE BEAUTÉ.",
    subtitle_with_code: `Entrez votre code unique pour rejoindre le <b>Valentino Beauty Dreams Club</b> et bénéficier d'avantages exclusifs.<br><br>Venez également jeter un œil sur nos derniers lancements produits et services exclusifs sur <a target="_blank" class='underline' href="https://www.valentino-beauty.be/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club">valentino-beauty.be</a>.`,
    title: "LE LIEU IDÉAL POUR CRÉER VOTRE PROPRE RÊVE DE BEAUTÉ.",
    subtitle: "Inscrivez-vous pour profiter d'offres et de services exclusifs.",
    code_exaplanation_title: "Où trouver votre code?",
    code_explanation_description: "Votre code unique se trouve à l'intérieur de l'étui de votre produit.",
    not_mandatory: "*Champs obligatoires",

    form: {
      names: {
        gender: "genre",
        firstname: "prénom",
        lastname: "nom",
        birthday: "date de naissance",
        email: "adresse email",
        address: "adresse",
        zipcode: "code postal",
        city: "ville",
      },

      unique_code: "Entrez votre code unique*",
      gender: "Sélectionnez votre genre",
      civilities: { 'M*': 1, 'Mme*': 2, 'Neutre*': 3 },
      first_name: "Prénom*",
      last_name: "Nom*",
      birthday: "Date de naissance: JJ/MM/AAAA*",
      email: "Adresse mail*",
      address: "Adresse*",
      zipcode: "Code postal*",
      city: "Ville*",
      terms: "J'accepte les conditions générales*",
      iOptin_2_label: "En m'inscrivant, j'accepte de recevoir des emails et des SMS de Valentino Beauty.",
      submit: "S'inscrire",
    },
  },

  diffusion: {
    title: "MERCI !",
    subtitle_with_code: "Bienvenue dans le <b>Valentino Beauty Dreams Club</b>.<br />Une surprise vous attend dans votre boîte de réception.",
    subtitle_email_already_exist: "Vous êtes déjà membre du <b>Valentino Beauty Dreams Club</b>. Nous avons bien pris en compte votre code unique.",
    subtitle_bis_with_code: "Venez également jeter un oeil sur nos derniers lancements produits et services exclusifs sur <a href=\"https://www.valentino-beauty.be/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club\" target=\"_blank\"><b class=\"cursor-pointer\">valentino-beauty.be</b></a>.",
    subtitle: "Vous êtes inscrit !",
    subtitle_bis: "Suivez-nous sur Instagram pour découvrir nos actualités sur <b class=\"cursor-pointer\">@valentino.beauty</b>.",
  },

  end: {
    title: "Merci pour votre participation !",
    subtitle: "L’opération XXXX est désormais terminée. Le gagnant du tirage au sort sera contacté prochainement par email.",
    redirection: "Découvrir nos offres"
  },
  footer: {
    terms: "Termes et Conditions",
    mentions: footerMentions,
    cookies: "Politique d'utilisation des cookies",
    contact: {
      button: "Contactez-nous",
      email: "test@numberly.com",
      subject: "OP TITLE"
    },
    charteFb: "Charte de confidentialité",

    realisation: {
      button: "Copyright",
      address: "28, rue de Châteaudun 75009 Paris - France Tel : +33 1 49 49 06 60",
      email: "Email",
      contact: "N'hésitez pas à nous contacter.",
      mailto: "juliette@numberly.com",
      numberly: "http://www.numberly.com/fr"
    },

    footer_terms_btnText: "Règlement",
    footer_terms_modal: footerFooterTermsModal,
    footer_contact_btnText: "Contact",
    footer_contact_email: "contact@lequizduchef-bbq-XXXXXXX.fr",
    footer_contact_subject: "XXXXXXX -  XXXXXXXXXX",
    footer_chartefb_client: "XXXXXXX",
    footer_chartefb_btnText: "Charte de confidentialité",
    footer_chartefb_modal: footerFooterChartefbModal,
    footer_realisation_btnText: "Réalisation",
    footer_realisation_modal_1000mercis: footerFooterRealisationModal1000mercis,
    footer_realisation_modal_numberly: footerFooterRealisationModalNumberly,
    footer_mentions: footerFooterMentions,
    footer_mentions2: footerFooterMentions2
  },
  modals: {
    share_link: {
      title: modalsShareLinkTitle,
      subtitle: "en copiant l'URL",
      action: "COPIER",
      success: "Le lien a bien été copié"
    },
    summary: {
      title: "Vos amis invités",
      relance: "Relancer",
      registered: "Inscrit"
    },
    share_email: {
      title: "Invitez vos amis",
      email: "Email {index}",
      subtitle: "via leur adresse email",
      alreadyUsed: "L'adresse email renseignée est déjà utilisée.",
      mentions: "Les adresses email que vous indiquez ici nous permettront de transmettre à vos amis, vos invitations à venir jouer à ce jeu. Ces adresses ne seront ni conservées, ni réutilisées à l’issue du jeu.",
      submit: "J'envoie mes invitations",
      success: "L'email a bien été envoyé.|Les emails ont bien été envoyés."
    }
  },

  // https://docs.google.com/spreadsheets/d/1Ym_O37QBF3-n0Ub1FlDzkIwe3CtSAhMDlFlia18pYLU/edit#gid=335035831
  errors: {
    unique_code_burned: 'Ce code a déjà été enregistré',
    unique_code_invalid: 'Ce code semble incorrect',
    unique_code_required: 'Veuillez entrer votre code unique',
    required: 'Veuillez indiquer votre {value}',
    invalid: 'Votre {value} semble incorrect',
    required_checkbox: 'Veuillez accepter les conditions générales',

    gender: {
      required: 'Veuillez indiquer votre genre',
    },

    firstname: {
      required: 'Veuillez indiquer votre prénom',
      invalid: 'Votre prénom semble incorrect',
    },

    lastname: {
      required: 'Veuillez indiquer votre nom',
      invalid: 'Votre nom semble incorrect',
    },

    birthday: {
      required: 'Veuillez indiquer votre date de naissance',
      invalid: 'Votre date de naissance est incorrecte',
      too_young: 'Vous devez avoir {value2} ans pour vous inscrire',
      too_old: 'Votre date de naissance est incorrecte',
    },

    email: {
      required: 'Veuillez indiquer votre adresse mail',
      invalid: 'Le champ email doit être une adresse e-mail valide',
      alreadyUsed: "Vous faites déjà partie du <b>Valentino Beauty Dreams Club</b>.",
      alreadyRegistered: "Vous faites déjà partie du <b>Valentino Beauty Dreams Club</b>.",
      noEmail: 'Veuilllez saisir au moins un email',
      duplicateEmail: 'Au moins 2 des emails que vous avez saisis sont identiques',
      ownEmail: 'Vous ne pouvez pas saisir votre propre email'
    },

    address: {
      required: 'Veuillez indiquer votre adresse',
      invalid: 'Veuillez indiquer une adresse valide',
    },

    zipcode: {
      required: 'Veuillez indiquer votre code postal',
      invalid: 'Veuillez indiquer un code postal valide',
      min: 'Veuillez indiquer un code postal valide',
    },

    city: {
      required: 'Veuillez indiquer votre ville',
      invalid: 'Le nom de votre ville est incorrect',
    },
  },
};
