<template>
  <div v-html="terms[decliName]" class="terms"></div>
</template>

<script>
import termsFrContent from '@/docs/terms/fr-pdf.dirt.html';
import termsUsContent from '@/docs/terms/us-pdf.dirt.html';
import termsUkContent from '@/docs/terms/uk-pdf.dirt.html';
import termsItContent from '@/docs/terms/it-pdf.dirt.html';
import termsEsContent from '@/docs/terms/es-pdf.dirt.html';
import termsDeContent from '@/docs/terms/de-pdf.dirt.html';
import termsNlContent from '@/docs/terms/nl-pdf.dirt.html';
import termsBeContent from '@/docs/terms/be-pdf.dirt.html';
import termsAtContent from '@/docs/terms/at-pdf.dirt.html';
import termsChContent from '@/docs/terms/ch-pdf.dirt.html';

export default {
  name: "TermsModal",

  computed: {
    decliName() {
      return this.$store.state.config.appConfig.activeDecli.name;
    },

    chLanguage() {
      return this.$store.state.config.chLanguage;
    },

    terms() {
      const obj = {
        fr: termsFrContent,
        us: termsUsContent,
        uk: termsUkContent,
        it: termsItContent,
        es: termsEsContent,
        de: termsDeContent,
        nl: termsNlContent,
        be: termsBeContent,
        at: termsAtContent,
        ch: termsChContent,
      };

      if (this.chLanguage === 'fr') {
        obj.ch = termsFrContent;
      } else {
        obj.ch = termsDeContent;
      }

      return obj;
    },
  },
  mounted() {
    this.$emit('updateSize', 'max')
  }
};
</script>

<style lang="scss" scoped>
.terms {
  ::v-deep h1, ::v-deep h2, ::v-deep h3, ::v-deep h4, ::v-deep h5, ::v-deep h6 {
    margin: 1em 0;
  }
  ::v-deep h1 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  ::v-deep h2 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  ::v-deep p {
    line-height: 18px;
    font-size: 15px;
  }
}
</style>
