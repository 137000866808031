export default [
  {
    decli: 'fr',
    staging: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-domain-script': 'a822f4cb-aeef-48a2-a6de-96d800765ef4-test',
      },

      optanonScript: () => {if(null==document.getElementById("Save Container")){var e=document.getElementById("accept-recommended-container"),t=document.getElementsByClassName("save-preference-btn-container")[0];const n=document.createElement("div");n.Id="Save Container";const a=document.createElement("div");a.Id="Accept Container";document.getElementsByClassName("#onetrust-pc-sdk .button-theme");n.innerHTML='<button id="Save Container" class="save-preference-btn-handler onetrust-close-btn-handler button-theme" style="margin-top: 0px; margin-left: 64px;" aria-label="Confirm My Choices" role="button" tabindex="0">Enregistrer les paramètres</button>',a.innerHTML='<button id="accept-recommended-btn-handler" class="button-theme" style="margin-top: 0px; margin-left: 60px;" aria-label="Allow All" role="button" tabindex="0";">Tout autoriser</button>',e.appendChild(n),t.appendChild(a),document.getElementsByClassName("button-theme")[2].marginRight="10px"}},
    },
    prod: {
      script: {
        type: 'text/javascript',
        charset: 'UTF-8',
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-domain-script': 'a822f4cb-aeef-48a2-a6de-96d800765ef4',
      },

      optanonScript: () => {if(null==document.getElementById("Save Container")){var e=document.getElementById("accept-recommended-container"),t=document.getElementsByClassName("save-preference-btn-container")[0];const n=document.createElement("div");n.Id="Save Container";const a=document.createElement("div");a.Id="Accept Container";document.getElementsByClassName("#onetrust-pc-sdk .button-theme");n.innerHTML='<button id="Save Container" class="save-preference-btn-handler onetrust-close-btn-handler button-theme" style="margin-top: 0px; margin-left: 64px;" aria-label="Confirm My Choices" role="button" tabindex="0">Enregistrer les paramètres</button>',a.innerHTML='<button id="accept-recommended-btn-handler" class="button-theme" style="margin-top: 0px; margin-left: 60px;" aria-label="Allow All" role="button" tabindex="0";">Tout autoriser</button>',e.appendChild(n),t.appendChild(a),document.getElementsByClassName("button-theme")[2].marginRight="10px"}},
    },
  },
  {
    decli: 'it',
    staging: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': '16b3f92a-8ee6-47fb-ba24-ae97a47c57ff',
      },

      optanonScript: () => { },
    },
    prod: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': '16b3f92a-8ee6-47fb-ba24-ae97a47c57ff',
      },

      optanonScript: () => { },
    },
  },
  {
    decli: 'de',
    html: {
      cookiesSettings: `<a class="optanon-toggle-display" href="#">Cookies settings</a>`,
    },
    staging: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': 'ff8148b5-d45f-4437-9026-e7370dacbb3a-test',
      },

      optanonScript: () => { },
    },
    prod: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': 'ff8148b5-d45f-4437-9026-e7370dacbb3a',
      },

      optanonScript: () => { },
    },
  },
  {
    decli: 'uk',
    html: {
      cookiesSettings: `<a href="#" onclick="Optanon.ToggleInfoDisplay();" target="">Cookie Settings</a>`,
    },
    prod: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-domain-script': '968a601f-3e83-4527-b5ed-c24cad94aad7-test',
      },

      optanonScript: () => {0==window.Optanon.IsAlertBoxClosed()&&window.Optanon.ToggleInfoDisplay();if(null==document.getElementById("Save Container")){var e=document.getElementById("accept-recommended-container"),t=document.getElementsByClassName("save-preference-btn-container")[0];const n=document.createElement("div");n.Id="Save Container";const o=document.createElement("div");o.Id="Accept Container";document.getElementsByClassName("#onetrust-pc-sdk .button-theme");n.innerHTML='<button id="Save Container" class="save-preference-btn-handler onetrust-close-btn-handler button-theme" style="margin-top: 0px;" aria-label="Confirm My Choices" role="button" tabindex="0">Confirm My Choices</button>',o.innerHTML='<button id="accept-recommended-btn-handler" class="button-theme" style="margin-top: 0px; margin-left: 0px;" aria-label="Allow All" role="button" tabindex="0";">Allow All</button>',e.appendChild(n),t.appendChild(o),document.getElementsByClassName("button-theme")[2].marginRight="10px"}},
    },
    staging: {
      script: {
        type: 'text/javascript',
        charset: 'UTF-8',
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-domain-script': '968a601f-3e83-4527-b5ed-c24cad94aad7',
      },

      optanonScript: () => {0==window.Optanon.IsAlertBoxClosed()&&window.Optanon.ToggleInfoDisplay();if(null==document.getElementById("Save Container")){var e=document.getElementById("accept-recommended-container"),t=document.getElementsByClassName("save-preference-btn-container")[0];const n=document.createElement("div");n.Id="Save Container";const o=document.createElement("div");o.Id="Accept Container";document.getElementsByClassName("#onetrust-pc-sdk .button-theme");n.innerHTML='<button id="Save Container" class="save-preference-btn-handler onetrust-close-btn-handler button-theme" style="margin-top: 0px;" aria-label="Confirm My Choices" role="button" tabindex="0">Confirm My Choices</button>',o.innerHTML='<button id="accept-recommended-btn-handler" class="button-theme" style="margin-top: 0px; margin-left: 0px;" aria-label="Allow All" role="button" tabindex="0";">Allow All</button>',e.appendChild(n),t.appendChild(o),document.getElementsByClassName("button-theme")[2].marginRight="10px"}},
    },
  },
  {
    decli: 'nl',
    staging: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-domain-script': 'da0bde18-a8e5-4876-9580-23ba1dc44078',
      },

      optanonScript: () => { },
    },
    prod: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-domain-script': 'da0bde18-a8e5-4876-9580-23ba1dc44078',
      },

      optanonScript: () => { },
    },
  },
  {
    decli: 'be',
    staging: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': '6aeac8cb-e242-4614-8a37-f3f296592520',
      },

      optanonScript: () => {

      if (window.Optanon.IsAlertBoxClosed() ==false)
      { 
      
      var a = document.getElementsByClassName("URL1");
      var b = document.getElementsByClassName("URL2");
      a[0].href = "";
      b[0].href = "/nl_BE/";
      a[1].href = "";
      b[1].href = "/nl_BE/";
      
      }
      
      },
    },
    prod: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': '6aeac8cb-e242-4614-8a37-f3f296592520',
      },

      optanonScript: () => { 
      if (window.Optanon.IsAlertBoxClosed() ==false)
      { 
      
      var a = document.getElementsByClassName("URL1");
      var b = document.getElementsByClassName("URL2");
      a[0].href = "";
      b[0].href = "/nl_BE/";
      a[1].href = "";
      b[1].href = "/nl_BE/";
      
      }
      
      },
    },
  },
  {
    decli: 'es',
    staging: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': '9786835e-d29c-4ace-b4f0-fdb5d9214c4f',
      },

      optanonScript: () => { },
    },
    prod: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': '9786835e-d29c-4ace-b4f0-fdb5d9214c4f',
      },

      optanonScript: () => { },
    },
  },
  {
    decli: 'at',
    html: {
      cookiesSettings: `<a class="optanon-toggle-display" href="#">Cookies settings</a>`,
    },
    staging: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': 'f65babc3-0c02-4f63-b018-a65dfe99a249-test'
      },

      optanonScript: () => { },
    },
    prod: {
      script: {
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'data-document-language': true,
        'data-domain-script': 'f65babc3-0c02-4f63-b018-a65dfe99a249'
      },

      optanonScript: () => { },
    },
  },
  {
    decli: 'ch',
    fr: {
      html: {
        cookiesSettings: `<a class="optanon-toggle-display" href="#">Cookies settings</a>`
      },
      staging: {
        script: {
          src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          'data-document-language': true,
          'data-domain-script': 'be59c035-c8ed-44b9-b194-b3d009038a3b-test'
        },
  
        optanonScript: () => { },
      },
      prod: {
        script: {
          src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          'data-document-language': true,
          'data-domain-script': 'be59c035-c8ed-44b9-b194-b3d009038a3b'
        },
        optanonScript: () => { },
      },
    },
    de: {
      html: {
        cookiesSettings: `<a class="optanon-toggle-display" href="#">Cookies settings</a>`
      },
      staging: {
        script: {
          src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          'data-document-language': true,
          'data-domain-script': 'be59c035-c8ed-44b9-b194-b3d009038a3b-test'
        },
  
        optanonScript: () => { },
      },
      prod: {
        script: {
          src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          'data-document-language': true,
          'data-domain-script': 'be59c035-c8ed-44b9-b194-b3d009038a3b'
        },
        optanonScript: () => { },
      },
    },
  },
];