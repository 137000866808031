<template>
  <div
    class="images-wrapper flex flex-col lg:flex-row items-center mx-auto px-8 pb-8"
    :class="[
      shouldDisplayImages
        ? 'justify-center lg:justify-between'
        : 'justify-center'
    ]"
  >
    <span
      v-if="shouldDisplayImages"
      class="mobile-image-wrapper block lg:hidden"
    >
      <img
        class="w-full max-w-xs h-auto mb-8 cursor-pointer"
        :src="mobileImage"
        alt="Voce Viva, the new Valentino Beauty fragrance"
        @click="exitUrl(decli != 'ch' ? 1 : $store.state.config.chLanguage == 'fr' ? 1 : 6)"
      />
    </span>

    <span
      v-if="shouldDisplayImages"
      class="image-wrapper hidden lg:block overflow-hidden"
    >
      <img
        class="block object-cover fixed max-w-full cursor-pointer"
        :src="leftImage"
        alt="Valentino Beauty woman in pink"
        @click="exitUrl(decli != 'ch' ? 1 : $store.state.config.chLanguage == 'fr' ? 1 : 6)"
      />
    </span>

    <span
      v-if="shouldDisplayImages"
      class="image-wrapper hidden lg:block overflow-hidden"
    >
      <img
        class="block object-cover fixed max-w-full cursor-pointer"
        :src="rightImage"
        alt="Voce Viva, the new fragrance"
        @click="exitUrl(decli != 'ch' ? 1 : $store.state.config.chLanguage == 'fr' ? 1 : 6)"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: "ImagesWrapper",
  props: {
    decli: {},
    exitUrl: {},
    hasLeftImage: {
      default: true
    },
    leftImage: {
      default: function() {
        return require("@/assets/images/wrapper/left-desktop.jpeg");
      }
    },
    mobileImage: {
      default: function() {
        return require("@/assets/images/wrapper/mobile.jpg");
      }
    },
    rightImage: {
      default: function() {
        return require("@/assets/images/wrapper/right-desktop.jpeg");
      }
    },
    hasRightImage: {
      default: true
    }
  },

  data() {
    return {
      shouldDisplayImages: true
    };
  },

  mounted() {
    if (this.$route.name === "diffusion") {
      this.shouldDisplayImages = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.images-wrapper {
  max-width: 1440px;

  @media (min-width: 1024px) {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.image-wrapper {
  position: relative;
  img {
    width: 100%;
    max-width: 264px;
    margin: auto;
    position: absolute;
  }

  @media (min-width: 1024px) {
    height: 420px;
    width: 234px;
  }

  @media (min-width: 1280px) {
    // height: 341px;
    object-fit: contain;
  }

  @media (min-width: 1280px) and (min-height: 600px) {
    height: 641px;
    width: 374px;
  }
}
</style>
