import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store';

Vue.use(VueI18n)

import { getDecli } from '@/helpers'
import appConfig from '@/declis'
const localeName = getDecli(appConfig.declis).name

function loadLocaleMessages () {
  const messages = {}

  if (localeName === 'ch') {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');

    if (lang && lang === 'fr') {
      messages[localeName] = require(`@/declis/${localeName}/fr/index.js`).default.translations;
      store.dispatch('config/updateChLanguage', 'fr');
    } else {
      messages[localeName] = require(`@/declis/${localeName}/de/index.js`).default.translations;
      store.dispatch('config/updateChLanguage', 'de');
    }
  } else {
    messages[localeName] = require(`@/declis/${localeName}/index.js`).default.translations;
  }

  return messages;
}

const i18n = new VueI18n({
  locale: localeName || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

export default i18n
