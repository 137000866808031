<template>
  <div class="h-screen flex flex-col items-center pt-16">
    <a href="https://www.valentino-beauty.de/" target="_blank" class="cursor-pointer">
      <img src="@/assets/logos/valentino-beauty.svg" alt="" class="block w-56 h-auto mb-8" />
    </a>

    <h1 v-if="!isOptinTooLate" class="max-w-lg mb-4 text-xl text-center" v-html="$t('optinisation.title')"></h1>

    <p
      class="max-w-lg text-base"
      v-html="
        isOptinTooLate
          ? $t('optinisation.subtitleTooLate')
          : $t('optinisation.subtitle', { client: 'CLIENT_NAME' })
      "
    ></p>
  </div>
</template>

<script>
export default {
  name: 'OptinisationPage',

  data() {
    return {
      isOptinTooLate: false,
    };
  },

  mounted() {
    this.$requestAPI
      .getSessionCurrent()
      .then((result) => {
        if (result.data.data[0].oOptins.iOptin_2 === 6) {
          this.isOptinTooLate = true;
        } else {
          this.$mmTro.doubleoptin(this.$route.query);
        }

        return result;
      })
      .catch(console.log);
  },
};
</script>
