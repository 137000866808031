import translations from '../../translations/ch_fr';

export default {
  ...translations,

  legal: {
    label: 'Mentions Légales',
    url: 'https://www.valentino-beauty.ch/service-client/conditions-d-utilisation-du-site/customer-service-terms-and-conditions.html'
  },

  contact: {
    email: 'contact@welcome.valentino-beauty.ch',
    from: 'Valentino Beauty',
  },

  register_optin: {
    no_code: {
      iOptin_2_label: `Je souhaite recevoir par e-mail les offres personnalisées, les éditions limitées, et les avant-premières Valentino Beauty et je confirme avoir au moins 18 ans. Je peux retirer mon consentement à tout moment via le lien de désinscription dans chaque e-mail.`,
    },

    code: {
      terms: `J'accepte les <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Conditions générales</a> du programme de fidélité de Valentino Beauty.*`,
      iOptin_5_label: `Je souhaite participer au programme fidélité Valentino Beauty Dreams Club. Les informations recueillies sur ce formulaire sont traitées par L’Oréal pour le compte de sa marque Valentino Beauty à des fins de gestion et d’administration de votre compte fidélité, ainsi qu’à des fins statistiques et analytiques.`,
      iOptin_2_label: `Je souhaite recevoir par e-mail les offres personnalisées, les éditions limitées, et les avant-premières Valentino Beauty et je confirme avoir au moins 18 ans. Je peux retirer mon consentement à tout moment via le lien de désinscription dans chaque e-mail.`,
    },
  },
};
