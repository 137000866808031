import { inIframe } from '@/helpers';
import store from '@/store';
import token from './token';
import registerAuto from './registerAuto';
import redirectToIframe from './iframe';
import auth from './auth';

/**
 * @param {import('vue-router').Route} to
 * @param {import('vue-router').Route} from
 * @param {(any) => void} next
 */
export default function(to, from, next) {
  const { sUid, iframe, e, insc } = to.query;

  const activeDecliConfig = store.getters['config/activeDecliConfig'];
  const isRelog = store.getters['user/isRelog'];
  const uid = store.getters['user/uid'];

  const isInIframe = inIframe();

  const isClassicRelog = !isInIframe && ((sUid && !isRelog) || (sUid && uid && uid.toLowerCase() !== sUid.toLowerCase()));
  const isIframeRelog = isInIframe && uid && !isRelog;

  // Iframe redirection, disable iframe redirection if found `iframe=no` URL query parameter.
  if (activeDecliConfig.iframe.active && !isInIframe && iframe !== "no") {
    redirectToIframe(to, from, next);
  }
  // Authorization and resume where the user has left. Compare sUid from URL and store state.
  else if (isIframeRelog || isClassicRelog) {
    auth(to, from, next);
  } else {
    return token(to)
      .then(() => {
        if (e && Number(insc) === 1 && activeDecliConfig.optinisation.active) {
          registerAuto(to, next);
        } else {
          next();
        }
      })
      .catch(() => {
        next({ name: 'ErrorPage' });
      });
  }
}