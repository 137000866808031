export default {
  state: {
    is_facebook: false,
    is_instagram: false,
    is_twitter: false,
    is_snapchat: false,
    is_linkedin: false,
  },

  actions: {
    checkIfWebView({ commit }) {
      commit('CHECK_IF_WEB_VIEW');
    },
  },

  mutations: {
    CHECK_IF_WEB_VIEW(state) {
      const ua = window.navigator.userAgent.toLowerCase();

      state.is_facebook = ua.indexOf('fban') > -1 || ua.indexOf('fbav') > -1;
      state.is_instagram = ua.indexOf('instagram') > -1;
      state.is_snapchat = ua.indexOf('snapchat') > -1;
      state.is_twitter = ua.indexOf('twitter') > -1;
      state.is_linkedin = ua.indexOf('linkedin') > -1;
    },
  },

  getters: {
    isInFacebook: (state) => state.is_facebook,
    isInInstagram: (state) => state.is_instagram,
    isInSnapchat: (state) => state.is_snapchat,
    isInTwitter: (state) => state.is_twitter,
    isInLinkedin: (state) => state.is_linkedin,
    isInWebView: (state) =>
      state.is_facebook || state.is_instagram || state.is_snapchat || state.is_twitter || state.is_linkedin,
  },
};
