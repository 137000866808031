import Doorman from '@/router/doorman';

export default [
  {
    name: 'welcome',
    path: 'welcome',
    component: () => import('@/views/Welcome'),
    meta: {
      pageId: 9999,
      autoPageView: true,
      requiresAuth: false,
      afterEnd: false,
      nextPage: 'register',
    },
    beforeEnter(to, from, next) {
      Doorman(to, from, next);
    },
  },
  {
    name: 'register',
    path: '',
    component: () => import('@/views/Register'),
    meta: {
      pageId: 1,
      autoPageView: true,
      requiresAuth: false,
      afterEnd: false,
      conversionPage: true,
    },
    beforeEnter(to, from, next) {
      Doorman(to, from, next);
    },
  },
  {
    name: 'confirmation',
    path: 'confirmation',
    component: () => import('@/views/Diffusion'),
    meta: {
      pageId: 2,
      autoPageView: true,
      afterEnd: false,
      requiresAuth: true,
    },
    beforeEnter(to, from, next) {
      Doorman(to, from, next);
    },
  },
];
