import cookiesBanner from './html/es/cookies-banner.html';
import cookiesModalContent from './html/es/cookies-modal-content.html';
import desaboLink from './html/es/unsubscribe.html';

export default {
  countries: {
    australia: 'Australia',
    austria: 'Austria',
    belgium: 'Bélgica',
    bulgaria: 'Bulgaria',
    canada: 'Canadá',
    chile: 'Chile',
    croatia: 'Croatia',
    czech_republic: 'República Checa',
    france: 'Francia',
    germany: 'Alemania',
    hungary: 'Hungría',
    iraq: 'Iraq',
    ireland: 'Irlanda',
    israel: 'Israel',
    italy: 'Italia',
    jordan: 'Jordania',
    lebanon: 'Líbano',
    mexico: 'México',
    morocco: 'Marruecos',
    netherlands: 'Países Bajos',
    new_zealand: 'Nueva Zelanda',
    poland: 'Polonia',
    portugal: 'Portugal',
    romania: 'Rumanía',
    russia: 'Rusia',
    saudi_arabia: 'Arabia Saudí',
    serbie: 'Serbia',
    slovakia: 'Eslovaquia',
    south_africa: 'Sudáfrica',
    spain: 'España',
    switzerland: 'Suiza',
    turkey: 'Turquía',
    uk: 'Reino Unido',
    ukraine: 'Ucrania',
    united_arab_emirates: 'Emiratos árabes Unidos',
    usa: 'Estados Unidos',
  },

  languages: {
    french: 'francés',
    german: 'alemán',
  },

  cookies: {
    banner: cookiesBanner,
    modal: {
      content: cookiesModalContent
    }
  },

  desabo: {
    title: "You have been unsubscribed successfully",
    link: desaboLink,
    resub: "You have been resubscribed"
  },

  welcome: {
    select_country: "Por favor seleccione su país*",
    select_country_placeholder: "Seleccione su país",
    select_country_error: "Por favor seleccione su país",
    select_language: "Por favor seleccione su idioma de preferencia*",
    select_language_placeholder: "Seleccione su idioma de preferencia",
    select_language_error: "Por favor seleccione su idioma de preferencia",
    cta_code: "Tengo un código único",
    cta_no_code: "No tengo un código único",
    not_available: "Lo sentimos, desafortunadamente el <b>Valentino Beauty Dreams Club</b> no está disponible en el país seleccionado. Le invitamos a seguirnos en nuestra cuenta de instagram para al tanto de las últimas novedades <b class=\"cursor-pointer\"><a href=\"https://www.instagram.com/valentino.beauty/\" target=\"_blank\">@valentino.beauty</a></b>.",
    go_back: "Cambiar de país",
  },

  register: {
    title_with_code: "ESTE ES EL LUGAR PERFECTO PARA CREAR TUS PROPIOS SUEÑOS DE BELLEZA",
    subtitle_with_code: "Únase al <b>Valentino Beauty Dreams Club</b> para expresar su personalidad y disfrutar de ventajas exclusivas.<br><br>Consulte también nuestros últimos lanzamientos de productos y servicios exclusivos en <a target='_blank' class='underline' href='https://www.valentino-beauty.es/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club'>valentino-beauty.es.</a>",
    title: "ESTE ES EL LUGAR PERFECTO PARA CREAR TUS PROPIOS SUEÑOS DE BELLEZA.",
    subtitle: "Regístrese para disfrutar de ofertas y servicios exclusivos.",
    code_exaplanation_title: "¿Dónde encontrar tu código?",
    code_explanation_description: "Tu código único está en el interior de la caja del producto.",
    not_mandatory: "*Campos Obligatorios",

    form: {
      names: {
        gender: "sexo",
        firstname: "nombre",
        lastname: "apellidos",
        birthday: "fecha de nacimiento",
        email: "dirección de correo electrónico",
        address: "dirección",
        zipcode: "código postal",
        city: "ciudad",
      },

      unique_code: "Introduzca el código exclusivo*",
      gender: "Selecciona tu sexo.",
      civilities: { 'Sr.*': 1, 'Sra.*': 2, 'Neutral*': 3 },
      first_name: "Nombre*",
      last_name: "Apellidos*",
      birthday: "Fecha de nacimiento DD/MM/AAAA*",
      email: "Dirección de correo electrónico*",
      address: "Dirección*",
      zipcode: "Código Postal*",
      city: "Ciudad*",
      terms: "Acepto los términos y condiciones*",
      iOptin_2_label: "Al registrarme, estoy aceptando recibir emails y mensajes de texto de Valentino Beauty.",
      submit: "Registrarse",
    },
  },

  diffusion: {
    title: "GRACIAS!",
    subtitle_with_code: "Le damos la bienvenida al <b>Valentino Beauty Dreams Club</b>. Tiene una sorpresa esperando en su bandeja de entrada de correo.",
    subtitle_email_already_exist: "Ya es miembro del <b>Valentino Beauty Dreams Club</b>. Hemos recibido con éxito su código único.",
    subtitle_bis_with_code: "Consulte también nuestros últimos lanzamientos de productos y servicios exclusivos en <a href=\"https://www.valentino-beauty.es/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club\" target=\"_blank\"><b>valentino-beauty.es</b></a>.",
    subtitle: "Ya está registrado.",
    subtitle_bis: "Puede seguirnos en Instagram para estar al tanto de todas las novedades en <b class=\"cursor-pointer\">@valentino.beauty</b>.",
  },

  footer: {
    terms: "Términos y Condiciones",
    mentions: "Mentions",
    cookies: "Cookie use policy",
    contact: {
      button: "Contáctenos",
      email: "test@numberly.com",
      subject: "OP TITLE"
    },
    charteFb: "Privacy Policy",

    realisation: {
      button: "Copyright",
      address: "57-61 Mortimer Street - London W1W 8HS - Reino Unido<br />www.1000mercis.co.uk",
      email: "Email",
      contact: "No dudes en contactar con Dorothée Lacroix.",
      mailto: "contactuk@1000mercis.com",
      numberly: "http://www.numberly.com"
    },
  },
  modals: {
    share_link: {
      title: "Share the link with your friends",
      subtitle: "by copying the URL",
      action: "COPY",
      success: "Link copied successfuly"
    },
    summary: {
      title: "Your invited friends",
      relance: "Resend",
      registered: "Registered",
      button: "See my registered friends"
    },
    share_email: {
      title: "Invite your friends",
      subtitle: "via their email address",
      email: "Email {index}",
      alreadyUsed: "The email address has already been used.",
      mentions: "The email addresses given here will alllow us to send invitations to your friends to also take part in the quiz. These emails will not be retained nor used after the game.",
      submit: "Send my invites",
      success: "The email has been sent.|The emails have been sent."
    }
  },
  optinisation: {
    title: "Thank you!",
    subtitle: ""
  },
  // https://docs.google.com/spreadsheets/d/1Ym_O37QBF3-n0Ub1FlDzkIwe3CtSAhMDlFlia18pYLU/edit#gid=335035831
  errors: {
    unique_code_burned: 'El código ya ha sido registrado',
    unique_code_invalid: 'El codigo introducido no es correcto',
    unique_code_required: 'Por favor, introduce tu código exclusivo.',
    required: 'Por favor, introduce tu {value}',
    invalid: 'Tu {value} parece ser incorrecto',
    required_checkbox: 'El campo de términos es obligatorio',

    gender: {
      required: 'Por favor, introduce tu sexo',
    },

    firstname: {
      required: 'Por favor, escribe tu nombre',
      invalid: 'Tu nombre parece ser incorrecto',
    },

    lastname: {
      required: 'Por favor, escribe tu apellido',
      invalid: 'Tu apellido parece ser incorrecto',
    },

    birthday: {
      required: 'Por favor, indica tu fecha de nacimiento',
      invalid: "Tu fecha de nacimiento no es válida",
      too_young: "Debe tener al menos {value2} años para poder inscribirse",
      too_old: "Tu fecha de nacimiento no es válida",
    },

    email: {
      required: 'Por favor, introduce tu correo electrónico',
      invalid: "Por favor, introduce una dirección de correo electrónico válida",
      alreadyUsed: "Ya forma parte del <b>Valentino Beauty Dreams Club</b>.",
      alreadyRegistered: "Ya forma parte del <b>Valentino Beauty Dreams Club</b>.",
      noEmail: "Enter at least one email",
      duplicateEmail: "At least 2 of the emails you entered are identical",
      ownEmail: "You cannot use your own email"
    },

    address: {
      required: 'Por favor, indica tu dirección postal',
      invalid: 'Indica una dirección postal válida',
    },

    zipcode: {
      required: 'Por favor, introduce tu código postal',
      invalid: 'Introduce un código postal válido',
      min: 'Introduce un código postal válido'
    },

    city: {
      required: 'Por favor, introduce tu ciudad',
      invalid: 'Tu ciudad no es válida',
    },
  },
};