<template>
  <div>
    <cookies />

    <div
      class="relative z-10 flex items-center justify-center w-full py-6 cursor-pointer"
    >
      <img
        @click="exitUrl(logoExitUrlLinkNumber)"
        src="@/assets/logos/valentino-beauty.svg"
        alt="Valentino Beauty"
      />
    </div>

    <images-wrapper
      :decli="appConfig.activeDecli.name"
      class="z-0"
      :exitUrl="exitUrl"
    />

    <div class="relative z-10 mx-auto router-view-content">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <modal
      :modal-config="modalConfig"
      @close="updateModalConfig({ isOpen: false, modalComponent: '' })"
    />

    <site-footer />

    <div class="cookie-policy-wrapper" ref="cookie-policy-wrapper"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Modal from "@/components/modals/Modal";
import Cookies from "@/components/Cookies";
import ImagesWrapper from "@/components/ui/ImagesWrapper";
import cookielaw from "@/cookielaw";
import { isDevelopmentEnv, isStagingEnv } from "@/helpers";

export default {
  name: "Container",

  components: {
    Modal,
    Cookies,
    ImagesWrapper
  },

  computed: {
    ...mapGetters(["modalConfig"]),
    ...mapGetters({
      stateParams: "user/stateParams",
      token: "global/token",
      idu: "user/idu",
      visitId: "global/visitId",
      appConfig: "config/appConfig",
      user: "user/user",
    }),

    chLanguage() {
      return this.$store.state.config.chLanguage;
    },

    logoExitUrlLinkNumber() {
      if (this.appConfig.activeDecli.name !== 'ch') {
        return 1;
      } else if (this.chLanguage === "fr") {
        return 1;
      } else if (this.chLanguage === "de") {
        return 5;
      }

      return 1;
    },
  },

  methods: {
    ...mapActions(["updateModalConfig"]),
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      console.log(linknumber);
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName}/${this.appConfig.activeDecli.apiVersion}/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    },
    appendExternalCookies() {
      const env = !isStagingEnv && !isDevelopmentEnv ? "prod" : "staging";

      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get("lang") ? urlParams.get("lang") : "fr";

      const activeDecliName = this.$store.state.config.appConfig.activeDecli
        .name;
      const cookie = cookielaw.find(cookie => cookie.decli === activeDecliName);

      if (cookie) {
        const cookieScriptObj =
          activeDecliName === "ch" ? cookie[lang][env] : cookie[env];

        if (
          cookieScriptObj.script &&
          Object.values(cookieScriptObj.script).length > 0
        ) {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;

          Object.entries(cookieScriptObj.script).forEach(([key, value]) => {
            script.setAttribute(key, value);
          });

          document.head.appendChild(script);
        }

        if (cookieScriptObj.optanonScript) {
          window.OptanonWrapper = cookieScriptObj.optanonScript
        }

        if (cookie.html && cookie.html.cookiesList) {
          this.$refs["cookie-policy-wrapper"].innerHTML =
            cookie.html.cookiesList;
        }
      }
    }
  },

  mounted() {
    // OPEN TERMS MODAL
    if (
      this.$route.query.modal != null &&
      this.$route.query.modal === "terms"
    ) {
      this.updateModalConfig({ isOpen: true, modalComponent: "TermsModal" });
    } else if (
      (this.$route.query.modal != null &&
        this.$route.query.modal === "privacy") ||
      (this.stateParams != null && this.stateParams.modal === "privacy")
    ) {
      this.updateModalConfig({ isOpen: true, modalComponent: "CharteFbModal" });
    }

    // This is required for OneTrust banner in CH decli (2 languages), because OneTrust
    // uses the `lang` attribute on the `<html>` element to determine the language.
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get("lang")) {
      document.documentElement.setAttribute('lang', urlParams.get("lang"));
    }

    this.appendExternalCookies();
  }
};
</script>

<style lang="scss" scoped>
.router-view-content {
  min-width: 320px;
  max-width: 350px;

  @media (min-width: 1024px) {
    /* 100vh - header height */
    min-height: calc(100vh - 98px);
  }
}
</style>
