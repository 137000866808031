/**
 * Detect app running in production by checking `localhost, mm-dev3, st1-mm, numberly`
 * keywords **not** present in the URL.
 *
 * @returns {boolean}
 */
export const isProdEnv = function () {
  return !(document.location.hostname.search(/(localhost)|(mm-dev3)|(st1-mm)|(numberly)/) > -1)
}

/**
 * Detect app running in staging by detecting `numberly.st` keyword in the URL.
 *
 * @returns {boolean}
 */
export const isStagingEnv = function () {
  return (document.location.hostname.search(/(numberly.st)/) > -1)
}

/**
 * Detect app running in staging by detecting `numberly.dev` keyword in the URL.
 *
 * @returns {boolean}
 */
export const isDevelopmentEnv = function() {
  return (document.location.hostname.search(/(numberly.dev)/) > -1);
}

// Detect debug mode on first call and keep data
const debugModeEnabled = (/debug=1/gi).test(window.location.search)

/**
 * Detect app running in debug mode.
 * To activate debug mode add param debug=1 to query string.
 *
 * @returns {boolean}
 */
export const isDebugMode = () => debugModeEnabled

/**
 * Remove multiples whitespaces and remove whitespace from both sides of a string.
 *
 * @returns {string}
 */
export const removeSpaces = function (string) {
  return string.replace(/\s\s+/g, ' ').trim();
}

/**
 * Returns the selected decli configuration by searching in the URL parts of the
 * decli config `urls` array.
 *
 * @param {any[]} declis 
 * @returns {any}
 */
export const getDecli = function (declis) {
  let selectedDecli;

  if ((isDevelopmentEnv() || isStagingEnv()) && !isProdEnv()) {
    const part = document.location.host.split('.')[0];
    const parts = part.split('-');
    const domain = parts[0];

    selectedDecli = declis.find((decli) => decli.urls.find((url) => domain === url));
  } else if (isProdEnv()) {
    let parts = document.location.host.split('.');

    // Handle split for .co.uk URLs. Remove the .co part and rename the .uk to .co.uk
    if (parts[parts.length - 2] === 'co' && parts[parts.length - 1] === 'uk') {
      parts = parts.filter((part) => part !== 'co');
      parts[parts.length - 1] = 'co.uk';
    }
  
    const domain = parts[parts.length - 1];

    selectedDecli = declis.find((decli) => decli.urls.find((url) => domain === url));
  } else if (document.location.host.indexOf('localhost')) {
    let parts = document.location.host.split('.');
    const domain = parts[0];

    selectedDecli = declis.find((decli) => decli.urls.find((url) => domain === url));
  }


  return selectedDecli || declis[0];
}

/**
 * Check if in iframe context.
 *
 * @returns {boolean}
 */
export const inIframe = function () {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

/**
 * Map all sub-config objects of a decli to an object.
 *
 * @param {__WebpackModuleApi.RequireContext} context webpack require context
 * @returns {object} decli config
 */
export const decliEntryPoint = function (context) {
  return context.keys().reduce((toExport, key) => {
    // Remove `./` and file extensions
    const path = key
      .replace(/(^.\/)|(\.js$)|(\.ya?ml$)|(.json)/g, '')
      .split('/')
  
    if (path[0] === 'content') {
      toExport.content = Object.assign(toExport.content, context(key))
    } else if (path[0] !== 'index') {
      toExport[path[0]] = context(key).default
    }
  
    return toExport
  }, { content: {} })
}


export const getDeviceType = function () {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};