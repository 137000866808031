<template>
  <div>
    <header>
      <h3 class="text-xl text-primary" >{{$t('modals.summary.title')}}</h3>
    </header>
    <ul class='list-friends list-unstyled'>
      <li v-for='(friend, key) in friends' :key='key' class='flex flex-wrap items-center justify-between friends-list-li row'>
        <span class="table">{{ friend.Email }}</span>
        <span class="table pt-2 pl-4 m-auto mx-auto sm:mx-0">
          <NCta :data-cy="'friend-mail-'+key" type="a" :href="friend.mailto" :disabled="friend.subscribed" target="_blank" class="actions">
            {{ friend.subscribed ? $t("modals.summary.registered") : $t("modals.summary.relance") }}
          </NCta>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NCta from '@team-uep/n-cta'

export default {
  name: 'summaryModal',
  data () {
    return {
      friends: []
    }
  }, 
  components: {
    NCta
  },
  methods: {
    getFriendsList () {
      this.$requestAPI.getSummaryViral()
      .then(result => {
        this.friends = result.data.data
        this.friends.map(friend => {
          const refVars = {
            // ...this.session,
            websiteUrl: window.location.origin,
            FriendEmail: friend.Email,
            id_UE_Envoi: friend.id_UE_Envoi
          }
          friend.mailto = `mailto:${friend.Email}?subject=${encodeURIComponent(this.$t('diffusionPage_summary_relance_subject'))}&body=${encodeURIComponent(this.$t('diffusionPage_summary_relance_body', refVars))}`
          return friend
        })
      })
    }
  },
  mounted () {
    this.getFriendsList()
  }
}
</script>

<style scoped>
.actions .cta-wrapper button ::v-deep {
  width: 100%;
}
@screen sm {
.list-friends {
    width: 500px;
  }
}
</style>
