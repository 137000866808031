import cookiesBanner from './html/it/cookies-banner.html';
import cookiesModalContent from './html/it/cookies-modal-content.html';
import desaboLink from './html/it/unsubscribe.html';

export default {
  countries: {
    australia: 'Australia',
    austria: 'Austria',
    belgium: 'Belgio',
    bulgaria: 'Bulgaria',
    canada: 'Canada',
    chile: 'Cile',
    croatia: 'Croazia',
    czech_republic: 'Repubblica Ceca',
    france: 'Francia',
    germany: 'Germania',
    hungary: 'Ungheria',
    iraq: 'Iraq',
    ireland: 'Irlanda',
    israel: 'Israele',
    italy: 'Italia',
    jordan: 'Giordania',
    lebanon: 'Libano',
    mexico: 'Messico',
    morocco: 'Marocco',
    netherlands: 'Paesi Bassi',
    new_zealand: 'Nuova Zelanda',
    poland: 'Polonia',
    portugal: 'Portogallo',
    romania: 'Romania',
    russia: 'Russia',
    saudi_arabia: 'Arabia Saudita',
    serbie: 'Repubblica di Serbia',
    slovakia: 'Repubblica Slovacca',
    south_africa: 'Sudafrica',
    spain: 'Spagna',
    switzerland: 'Svizzera',
    turkey: 'Turchia',
    uk: 'Regno Unito',
    ukraine: 'Ucraina',
    united_arab_emirates: 'Emirati Arabi Uniti',
    usa: 'Stati Uniti',
  },

  languages: {
    french: 'Francese',
    german: 'Tedesco',
  },

  cookies: {
    banner: cookiesBanner,
    modal: {
      content: cookiesModalContent
    }
  },

  desabo: {
    title: "La tua domanda é stata registrata .<br/> Se hai cliccato per errore, <a href=\"{0}\">clicca qui</a> per continuare a ricevere le nostre email",
    link: desaboLink,
    resub: "Continuerai a ricevere le nostre email",
  },

  welcome: {
    select_country: "Selezionare il proprio paese*",
    select_country_placeholder: "Selezionare il proprio paese",
    select_country_error: "Selezionare il proprio paese",
    select_language: "Selezionare la propria lingua*",
    select_language_placeholder: "Selezionare la propria lingua",
    select_language_error: "Selezionare la propria lingua",
    cta_code: "Ho un codice unico",
    cta_no_code: "Non ho un codice unico",
    not_available: "Siamo spiacenti, ma <b>Valentino Beauty Dreams Club</b> non è disponibile nel tuo paese. Ti invitiamo a seguirci su Instagram per scoprire le nostre ultime novità su <b class=\"cursor-pointer\"><a href=\"https://www.instagram.com/valentino.beauty/\" target=\"_blank\">@valentino.beauty</a></b>.",
    go_back: "Cambio paese",
  },

  register: {
    title_with_code: "QUESTO È IL POSTO PERFETTO PER CREARE IL TUO SOGNO DI BELLEZZA",
    subtitle_with_code: "Inserisci il tuo codice unico per entrare nel <b>Valentino Beauty Dreams Club</b> e ottenere vantaggi esclusivi.<br><br>Scopri i nostri nuovi prodotti e servizi esclusivi su <a class='underline' target='_blank' href='https://www.valentino-beauty.it/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club/' class='underline'>valentino-beauty.it</a>.",
    title: "QUESTO È IL POSTO PERFETTO PER CREARE IL TUO SOGNO DI BELLEZZA.",
    subtitle: "Registrati per usufruire di offerte e servizi esclusivi.",
    code_exaplanation_title: "Dove puoi trovare il tuo codice?",
    code_explanation_description: "Il tuo codice univoco è all'interno della confezione del prodotto.",
    not_mandatory: "*Campi obbligatori",

    form: {
      names: {
        gender: "sesso",
        firstname: "nome",
        lastname: "cognome",
        birthday: "data di nascita",
        email: "indirizzo email",
        address: "indirizzo",
        zipcode: "CAP",
        city: "città",
      },

      unique_code: "Inserisci il tuo codice unico*",
      gender: "Selezionare il proprio sesso",
      civilities: { 'Sig.*': 1, 'Sig.na*': 2, 'Neutro*': 3 },
      first_name: "Nome*",
      last_name: "Cognome*",
      birthday: "Data di nascita: GG/MM/AAAA*",
      email: "Indirizzo email*",
      address: "Indirizzo*",
      zipcode: "CAP*",
      city: "Città*",
      terms: "Accetto i Termini e le Condizioni*",
      iOptin_2_label: "Iscrivendomi accetto di ricevere email e SMS da Valentino Beauty.",
      submit: "Iscriviti",
    },
  },

  diffusion: {
    title: "GRAZIE!",
    subtitle_with_code: "Benvenuti al <b>Valentino Beauty Dreams Club!</b><br />Una sorpresa che ti aspetta nella tua casella di posta.",
    subtitle_email_already_exist: "Fai già parte del <b>Valentino Beauty Dreams Club</b>. Abbiamo preso nota del tuo codice unico.",
    subtitle_bis_with_code: "Scopri i nostri nuovi prodotti e servizi esclusivi su <a href=\"https://www.valentino-beauty.it/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club\" target=\"_blank\"><b>valentino-beauty.it</b></a>.",
    subtitle: "Ti sei registrato con successo.",
    subtitle_bis: "Seguici su Instagram su <b class=\"cursor-pointer\">@valentino.beauty</b> e scopri le nostre ultime novità.",
  },

  footer: {
    terms: "Termini e Condizioni",
    mentions: "Mentions",
    cookies: "Cookie use policy",
    contact: {
      button: "Contattaci",
      email: "test@numberly.com",
      subject: "OP TITLE"
    },
    charteFb: "Privacy Policy",
    realisation: {
      button: "Copyright",
      address: "57-61 Mortimer Street - London W1W 8HS - UK<br />www.numberly.com",
      email: "Email",
      contact: "Non esitare a contattarci",
      mailto: "contactuk@1000mercis.com",
      numberly: "http://www.numberly.com"
    }
  },
  modals: {
    share_link: {
      title: "Share the link with your friends",
      subtitle: "by copying the URL",
      action: "COPY",
      success: "Link copied successfuly"
    },
    summary: {
      title: "Your invited friends",
      relance: "Resend",
      registered: "Registered",
      button: "See my registered friends"
    },
    share_email: {
      title: "Invite your friends",
      subtitle: "via their email address",
      email: "Email {index}",
      alreadyUsed: "The email address has already been used.",
      mentions: "The email addresses given here will alllow us to send invitations to your friends to also take part in the quiz. These emails will not be retained nor used after the game.",
      submit: "Send my invites",
      success: "The email has been sent.|The emails have been sent."
    }
  },
  optinisation: {
    title: "Thank you!",
    subtitle: "",
  },
  // https://docs.google.com/spreadsheets/d/1Ym_O37QBF3-n0Ub1FlDzkIwe3CtSAhMDlFlia18pYLU/edit#gid=335035831
  errors: {
    unique_code_burned: 'Questo codice è già stato rigistrato',
    unique_code_invalid: 'Questo codice è errato',
    unique_code_required: 'Inserire il proprio codice univoco',
    required: 'Fornire il proprio {value}',
    invalid: 'Il {value} inserito risulta errato',
    required_checkbox: 'Il campo dei termini è obbligatorio',

    gender: {
      required: 'Fornire il proprio sesso',
    },

    firstname: {
      required: 'Inserire il proprio nome',
      invalid: 'Il nome inserito risulta errato',
    },

    lastname: {
      required: 'Inserire il proprio cognome',
      invalid: 'Il cognome inserito risulta errato',
    },

    birthday: {
      required: 'Inserire la propria data di nascita',
      invalid: "La data di nascita risulta errata",
      too_young: "Devi avere almeno {value2} anni",
      too_old: "La data di nascita risulta errata",
    },

    email: {
      required: 'Inserire il proprio indirizzo email',
      invalid: "Si prega di inserire un indirizzo email valido",
      alreadyUsed: "Fai già parte del <b>Valentino Beauty Dreams Club</b>.",
      alreadyRegistered: "Fai già parte del <b>Valentino Beauty Dreams Club</b>.",
      noEmail: "Enter at least one email",
      duplicateEmail: "At least 2 of the emails you entered are identical",
      ownEmail: "You cannot use your own email"
    },

    address: {
      required: 'Inserire il proprio indirizzo postale',
      invalid: 'Si prega di inserire un indirizzo postale valido',
    },

    zipcode: {
      required: 'Inserire il proprio codice postale',
      invalid: 'Si prega di inserire un codice postale valido',
      min: 'Si prega di inserire un codice postale valido',
    },

    city: {
      required: 'Inserire la propria città',
      invalid: 'Questa città è errata',
    },
  },
};