import CurrentRequestAPI from '@/api/current-request-api';

/**
 * Make `requestAPI` instance accessible from all Vue components under
 * `$requestAPI`.
 *
 * @param {import("vue").VueConstructor} Vue Vue constructor
 */
export default function plugin(Vue) {
  Vue.$requestAPI = new CurrentRequestAPI();

  Object.defineProperties(Vue.prototype, {
    $requestAPI: {
      get() {
        return new CurrentRequestAPI();
      },
    },
  });
}