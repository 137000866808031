import cookiesBanner from './html/en/cookies-banner.html';
import cookiesModalContent from './html/en/cookies-modal-content.html';
import desaboLink from './html/en/unsubscribe.html';

export default {
  countries: {
    australia: 'Australia',
    austria: 'Austria',
    belgium: 'Belgium',
    bulgaria: 'Bulgaria',
    canada: 'Canada',
    chile: 'Chile',
    croatia: 'Croatia',
    czech_republic: 'Czech Republic',
    france: 'France',
    germany: 'Germany',
    hungary: 'Hungary',
    iraq: 'Iraq',
    ireland: 'Ireland',
    israel: 'Israel',
    italy: 'Italy',
    jordan: 'Jordan',
    lebanon: 'Lebanon',
    mexico: 'Mexico',
    morocco: 'Morocco',
    netherlands: 'Netherlands',
    new_zealand: 'New Zealand',
    poland: 'Poland',
    portugal: 'Portugal',
    romania: 'Romania',
    russia: 'Russia',
    saudi_arabia: 'Saudi Arabia',
    serbie: 'Serbia',
    slovakia: 'Slovakia',
    south_africa: 'South Africa',
    spain: 'Spain',
    switzerland: 'Switzerland',
    turkey: 'Turkey',
    uk: 'United Kingdom',
    ukraine: 'Ukraine',
    united_arab_emirates: 'United Arab Emirates',
    usa: 'USA',
  },

  languages: {
    french: 'French',
    german: 'German',
  },

  cookies: {
    banner: cookiesBanner,
    modal: {
      content: cookiesModalContent
    }
  },

  desabo: {
    title: "You have been unsubscribed successfully",
    link: desaboLink,
    resub: "You have been resubscribed"
  },

  optinisation: {
    title: "Thank you!",
    subtitle: "You have successfully chosen to receive offers from Valentino Beauty. Should you wish, you can unsubscribe from emails at any point.",
  },

  welcome: {
    select_country: "Please select your country*",
    select_country_placeholder: "Select your country",
    select_country_error: "Please select your country",
    select_language: "Please select your language*",
    select_language_placeholder: "Select your language",
    select_language_error: "Please select your language",
    cta_code: "I have a unique code",
    cta_no_code: "I don't have a unique code",
    not_available: "Sorry, but the <b>Valentino Beauty Dreams Club</b> is not available in the selected country. We invite you to follow us on Instagram to discover our latest news at <b class=\"cursor-pointer\"><a href=\"https://www.instagram.com/valentino.beauty/\" target=\"_blank\">@valentino.beauty</a></b>.",
    go_back: "Change country",
  },

  register: {
    title_with_code: "This is the perfect place to create your own beauty dreams.",
    subtitle_with_code: `Enter your unique code to join the <b>Valentino Beauty Dreams Club</b> and enjoy exclusive benefits.<br><br>Also, discover our latest product launches and exclusive services at <a target="_blank" class='underline' href='https://www.valentino-beauty.co.uk/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club'>valentino-beauty.co.uk.</a>`,
    title: "THIS IS THE PERFECT PLACE TO CREATE YOUR OWN BEAUTY DREAM.",
    subtitle: "Register to enjoy exclusive offers and services.",
    code_exaplanation_title: "Where to find your code?",
    code_explanation_description: "Your unique code is displayed inside the product's package.",
    not_mandatory: "*Mandatory fields",

    form: {
      names: {
        gender: "gender",
        firstname: "first name",
        lastname: "lastname",
        birthday: "date of birth",
        email: "email address",
        address: "postal address",
        zipcode: "postcode",
        city: "city",
      },

      unique_code: "Enter your unique code*",
      gender: "Select your gender",
      civilities: { 'Mr*': 1, 'Mrs*': 2, 'Neutral*': 3 },
      first_name: "First name*",
      last_name: "Last name*",
      birthday: "Date of birth: DD/MM/YYYY*",
      email: "Email address*",
      address: "Address*",
      zipcode: "Postcode*",
      city: "City*",
      terms: "I accept the Terms and Conditions*",
      iOptin_2_label: "By signing up, I agree to receive emails and SMS from Valentino Beauty.",
      submit: "Sign up",
    },
  },

  diffusion: {
    title: "THANK YOU!",
    subtitle_with_code: "Welcome to the <b>Valentino Beauty Dreams Club</b>.<br />There is a surprise waiting for you in your inbox.",
    subtitle_email_already_exist: "You are already a member of the <b>Valentino Beauty Dreams Club</b>. We have received your unique code.",
    subtitle_bis_with_code: "Also, discover our latest product launches and exclusive services at <a href=\"https://www.valentino-beauty.co.uk/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club\" target=\"_blank\"><b>valentino-beauty.co.uk</b></a>.",
    subtitle: "You are registered!",
    subtitle_bis: "Follow us on Instagram to discover our latest news at <b class=\"cursor-pointer\">@valentino.beauty</b>.",
  },

  footer: {
    terms: "Terms & Conditions",
    mentions: "Mentions",
    cookies: "Cookie use policy",
    contact: {
      button: "Contact us",
      email: "test@numberly.com",
      subject: "OP TITLE"
    },
    charteFb: "Privacy Policy",
    realisation: {
      button: "Copyright",
      address: "57-61 Mortimer Street - London W1W 8HS - UK<br />www.1000mercis.co.uk ",
      email: "Email",
      contact: "Do not hesitate to contact us.",
      mailto: "Email: james@numberly.com",
      numberly: "http://www.numberly.com"
    }
  },
  modals: {
    share_link: {
      title: "Share the link with your friends",
      subtitle: "by copying the URL",
      action: "COPY",
      success: "Link copied successfuly"
    },
    summary: {
      title: "Your invited friends",
      relance: "Resend",
      registered: "Registered",
      button: "See my registered friends"
    },
    share_email: {
      title: "Invite your friends",
      subtitle: "via their email address",
      email: "Email {index}",
      alreadyUsed: "The email address has already been used.",
      mentions: "The email addresses given here will alllow us to send invitations to your friends to also take part in the quiz. These emails will not be retained nor used after the game.",
      submit: "Send my invites",
      success: "The email has been sent.|The emails have been sent."
    }
  },

  // https://docs.google.com/spreadsheets/d/1Ym_O37QBF3-n0Ub1FlDzkIwe3CtSAhMDlFlia18pYLU/edit#gid=335035831
  errors: {
    unique_code_burned: 'This code has already been registered',
    unique_code_invalid: 'This code appears to be incorrect',
    unique_code_required: 'Please enter your unique code',
    required: 'Please enter your {value}',
    invalid: 'Your {value} appears to be incorrect',
    required_checkbox: 'The terms field is required',

    gender: {
      required: 'Please provide your gender',
    },

    firstname: {
      required: 'Please enter your first name',
      invalid: 'Your first name appears to be incorrect',
    },

    lastname: {
      required: 'Please enter your lastname',
      invalid: 'Your lastmane appears to be incorrect',
    },

    birthday: {
      required: 'Please enter your date of birth',
      invalid: "Your date of birth is invalid",
      too_young: "You must be {value2} years old to register",
      too_old: "You must be less than {value2} years old to register",
    },

    email: {
      required: 'Please enter your email address',
      invalid: "Please enter a valid email address",
      alreadyUsed: "You're already part of the <b>Valentino Beauty Dreams Club</b>.",
      alreadyRegistered: "You're already part of the <b>Valentino Beauty Dreams Club</b>.",
      noEmail: "Enter at least one email",
      duplicateEmail: "At least 2 of the emails you entered are identical",
      ownEmail: "You cannot use your own email"
    },

    address: {
      required: 'Please enter your postal address',
      invalid: 'Please enter a valid postal address',
    },

    zipcode: {
      required: 'Please enter your postcode',
      invalid: 'Please enter a valid postcode',
      min: 'Please enter a valid postcode'
    },

    city: {
      required: 'Please enter your city',
      invalid: 'Your city is invalid',
    },
  },
};