export default class Hooks {
  
  constructor (authorizedLabels) {
    this._authorizedLabels = authorizedLabels

    // List pending hook to avoid call 2x the same hook
    // to avoid recursive callbacks
    this._pendingList = []
    
    this._callbacks = {  }
  }

  /**
   * Add a hook to a label
   * 
   * @param {String} label        Label of the hook
   * @param {Function} callback   Function called when hook dispatched
   */
  add (label, callback) {
    if (this._authorizedLabels.find(argLabel => label.match(argLabel))) {
      if (!this._callbacks[label] || !Array.isArray(this._callbacks[label])) {
        this._callbacks[label] = []
      }
      this._callbacks[label].push(callback)
    } else {
      throw new Error('The hook ' + label + ' does not exist')
    }
  }

  /**
   * Remove a hook
   * 
   * @param {String} label        Label of the hook
   * @param {Function} callback   Function called when hook dispatched
   */
  remove (label, callback) {
    if (this._callbacks[label] && Array.isArray(this._callbacks[label])) {
      const i = this._callbacks[label].indexOf(callback)
      if (i > -1) {
        this._callbacks[label].splice(i, 1)
      }
    }
  }

  /**
   * Test if hook is added
   * 
   * @param {String} label        Label of the hook
   */
  has (label) {
    // Disable hook inside same hook (to avoid recursive calls) 
    if (this._pendingList.indexOf(label) > -1) {
      return false
    } else {
      return this._callbacks[label] && Array.isArray(this._callbacks[label]) && (this._callbacks[label].length > 0)
    }
  }

  lock(label) {
    this.unlock(label)
    this._pendingList.push(label)
  }

  unlock(label) {
    const i = this._pendingList.indexOf(label)
    if (i > -1) {
      this._pendingList.splice(i, 1)
    }
  }
  
  /**
   * Dispatch the hook
   * 
   * @param {String} label    Label of the hook
   * @param  {...any} data    All the arguments needed
   */
  async dispatch (label, ...data) {
    const results = []
    
    // Call hook only if they are not already in hook
    if (this.has(label)) {
      this.lock(label)
      
      for (let i = 0; i < this._callbacks[label].length; i++) {
        const callback = this._callbacks[label][i]
        const result = await callback(...data)
        results.push(result)
      }

      this.unlock(label)
    }

    return results
  }
} 