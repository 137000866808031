import translations from '../translations/en';

export default {
  ...translations,

  register_optin: {
    no_code: {
      iOptin_2_label: `By signing up, I agree to receive emails from VALENTINO beauty and other L'Oreal brands and programs. Click to read our (LINK) Privacy Policy. This site is intended for US consumers.`,
    },

    code: {
      iOptin_5_label: `I accept the Terms and Conditions (LINK).`,
      iOptin_2_label: `I'd love to receive emails from Valentino Beauty about exclusive online offers, early access to new products, beauty tips and more!<br />By signing up, I agree to receive emails from VALENTINO beauty and other L'Oreal brands and programs. Click to read our Privacy Policy (LINK). This site is intended for US consumers.`,
    },
  },
};
