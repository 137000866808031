import translations from '../translations/fr';

export default {
  ...translations,

  legal: {
    label: 'Mentions Légales',
    url: 'https://www.valentino-beauty.fr/service-client/conditions-d-utilisation-du-site/customer-service-terms-and-conditions.html'
  },

  contact: {
    email: 'contact@welcome.valentino-beauty.fr',
    from: 'Valentino Beauty',
  },

  register_optin: {
    no_code: {
      iOptin_2_label: `Je souhaite recevoir par e-mail les offres personnalisées, les éditions limitées, et les avant-premières Valentino Beauty et je confirme avoir au moins 16 ans. Je peux retirer mon consentement à tout moment via le lien de désinscription dans chaque e-mail.`,
    },
    code: {
      mention_age: `Vous devez avoir au moins 18 ans pour utiliser ce service.`,
      terms: `J'ai lu et j'accepte les <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Conditions générales du programme de fidélité </a>.`,
      terms2: `Les données que vous nous communiquez seront utilisées par L’Oréal France pour la gestion de votre programme de fidélité. Elles le seront également, avec votre consentement, pour enrichir votre profil et vous proposer les offres personnalisées par communication directe de Valentino Beauty, selon vos choix ci-dessus, ainsi que via des publicités de ses différentes marques sur des sites et réseaux sociaux partenaires. Vous pouvez retirer votre consentement à tout moment, notamment via le lien de désinscription présent dans nos communications électroniques.<br /><br />Pour en savoir plus sur le traitement de vos données et sur vos droits, consultez notre <a class="underline cursor-pointer" href="https://www.valentino-beauty.fr/mon-compte/politique-de-confidentialite/privacy-policy.html" target="_blank" rel="noopener noreferrer">Politique de confidentialité</a>.`,
      iOptin_2_label: `Je souhaite bénéficier d’offres personnalisées de L’Oréal France par communication directe, en relation avec les produits et services de Valentino Beauty, par E-mail ainsi que via des publicités des marques de L’Oréal France adaptées à mes intérêts affichées sur des sites et réseaux sociaux partenaires.`,
    },
  },
};
