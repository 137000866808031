<script>
export default {
  name: 'DynamicTemplate',
  functional: true,
  props: {
    name: String,
    template: String,
    data: { type: Object, default: () => ({}) },
    tag: {
      type: String,
      default: 'div'
    }
  },
  render (h, context) {
    if (context.props.template) {
      const checkIfInParentTag = /^<(.+?)>.*<\/(.+?)>$/.exec(context.props.template)

      const template = checkIfInParentTag &&
        checkIfInParentTag[1] &&
        checkIfInParentTag[2] &&
        checkIfInParentTag[1] === checkIfInParentTag[2]
        ? context.props.template
        : `<${context.props.tag}>${context.props.template}</${context.props.tag}>`

      const dynComponent = {
        name: context.props.name || 'DynamicTemplate',
        template,
        data () {
          return context.props.data
        }
      }
      const component = template ? dynComponent : ''
      return h(component)
    } else {
      return h({})
    }
  }
}
</script>
