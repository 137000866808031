<template>
  <NModal id="modal-wrapper" ref="modal" @close="()=> {$emit('close')}" :size="size" :visible="modalConfig.isOpen" :class="modalConfig.modalClass || ''">
    <component data-cy="modal-content" @updateSize="updateSize" :id="modalConfig.modalComponent" :ref="childrenRef" :is="modalConfig.modalComponent" />
  </NModal>
</template>

<script>
import CookiesModal from './CookiesModal.vue'
import TermsModal from './TermsModal.vue'
import CharteFbModal from './CharteFbModal.vue'
import RealisationModal from './RealisationModal.vue'
import DiffusionEmailsModal from './DiffusionEmailsModal.vue'
import ShareLinkModal from './ShareLinkModal.vue'
import SummaryModal from './SummaryModal.vue'
import WinnersModal from './WinnersModal.vue'

export default {
  name: 'Modal',
  props: ['modalConfig'],
  data() {
    return {
      size : 'min'
    }
  },
  components: {
    CookiesModal,
    TermsModal,
    CharteFbModal,
    RealisationModal,
    DiffusionEmailsModal,
    ShareLinkModal,
    SummaryModal,
    WinnersModal
  },
  computed :{
    childrenRef () {
      return 'contentModal' + '_' + ( this.modalConfig.modalComponent || '' )
    }
  },
  mounted() {
    this.updateSize()
  },
  methods: {
    updateSize (newSize) {
      if ( newSize ) {
        this.size = newSize
      } else {
        this.size = 'min'
      }
    }
  },
  watch: {
    'modalConfig.isOpen' (newVal) {
      this.updateSize()
    }
  }
}
</script>