import translations from '../translations/es';

export default {
  ...translations,

  legal: {
    label: 'Menciones Legales',
    url: 'https://www.valentino-beauty.es/servicio-de-atenci%C3%B3n-al-cliente/terminos-y-condiciones/customer-service-terms-and-conditions.html'
  },

  contact: {
    email: 'contact@welcome.valentino-beauty.es',
    from: 'Valentino Beauty',
  },

  register_optin: {
    no_code: {
      iOptin_2_label: `Acepto el envío de comunicaciones comerciales de L’Oréal España sobre sus productos y/o servicios por medios ordinarios y/o electrónicos.`,
    },

    code: {
      terms: `He leído y acepto los <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Términos y Condiciones</a> del Programa de Fidelidad.*`,
      iOptin_5_label: `Deseo participar al programa de fidelidad Valentino Beauty Dreams Club. Las finalidades principales de tratamiento de sus datos personales son: darle de alta en nuestro programa de fidelidad y enviarle comunicaciones comerciales y promocionales.`,
      iOptin_2_label: `Acepto el envío de comunicaciones comerciales de L’Oréal España sobre sus productos y/o servicios por medios ordinarios y/o electrónicos.`,
    },
  },
};
