export default {
  siteTitle: 'Valentino Beauty',
  prodUrl: 'welcome.valentino-beauty.com',
  facebook: {
    active: false,
    fbSdkLang: 'fr_FR',
    appIds: {
      staging: 'xxx',
      prod: 'xxx'
    }
  },
  twitter: {
    active: false
  },
  iframe: {
    active: false,
    urlToRedirTo: 'http://localhost:8080/'
  },
  optinisation: {
    active: false
  },
  idfroms: {
    untagged: 901,
    facebook: 200,
    twitter: 300,
    whatsapp: 400,
    messenger: 500,
    urlcopy: 600
  },
  linkNumbers: {
    facebook: 1,
    twitter: 2,
    urlcopy: 3,
    whatsapp: 4,
    messenger: 5
  }
}
