import translations from '../translations/nl';

export default {
  ...translations,

  legal: {
    label: 'Legal Notices',
    url: 'https://www.valentino-beauty.nl/klantenservice/gebruiksvoorwaarden/customer-service-terms-and-conditions.html'
  },

  contact: {
    email: 'contact@welcome.valentino-beauty.nl',
    from: 'Valentino Beauty',
  },

  register_optin: {
    no_code: {
      iOptin_2_label: `I would like to receive personalized offers, limited editions and Valentino Beauty previews. I confirm that I am at least 16 years old.`,
    },

    code: {
      terms: `I accept the <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Terms and Conditions.</a>*`,
      iOptin_5_label: `I agree with the <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Terms and Conditions</a> of the Loyalty Program. The information that will be collected through this form, will be registrered by L'Oréal SA for her brand Valentino Beauty with the purpose of managing and administering your loyalty account, as well as for statistical and analytical purposes and, with your consent, for the sending of personalized commercial communications.`,
      iOptin_2_label: `I would like to receive personalized offers, limited editions and Valentino Beauty previews. I confirm that I am at least 16 years old.`,
    },
  },
};
