import cookiesBanner from './html/de/cookies-banner.html';
import cookiesModalContent from './html/de/cookies-modal-content.html';
import desaboLink from './html/de/unsubscribe.html';

export default {
  countries: {
    australia: 'Australien',
    austria: 'Österreich',
    belgium: 'Belgien',
    bulgaria: 'Bulgarien',
    canada: 'Kanada',
    chile: 'Chile',
    croatia: 'Kroatien',
    czech_republic: 'Tschechische Republik',
    france: 'Frankreich',
    germany: 'Deutschland',
    hungary: 'Ungarn',
    iraq: 'Irak',
    ireland: 'Irland',
    israel: 'Israel',
    italy: 'Italien',
    jordan: 'Jordanien',
    lebanon: 'Libanon',
    mexico: 'Mexiko',
    morocco: 'Marokko',
    netherlands: 'Niederlande',
    new_zealand: 'Neuseeland',
    poland: 'Polen',
    portugal: 'Portugal',
    romania: 'Rumänien',
    russia: 'Russland',
    saudi_arabia: 'Saudi-Arabien',
    serbie: 'Serbien',
    slovakia: 'Slowakei',
    south_africa: 'Südafrika',
    spain: 'Spanien',
    switzerland: 'Schweiz',
    turkey: 'Türkei',
    uk: 'Vereinigtes Königreich',
    ukraine: 'Ukraine',
    united_arab_emirates: 'Vereinigte Arabische Emirate',
    usa: 'USA',
  },

  languages: {
    french: 'Französisch',
    german: 'Deutsche',
  },

  cookies: {
    banner: cookiesBanner,
    modal: {
      content: cookiesModalContent
    }
  },

  desabo: {
    title: "You have been unsubscribed successfully",
    link: desaboLink,
    resub: "You have been resubscribed"
  },

  optinisation: {
    title: "<b>Vielen Dank!</b>",
    subtitle: "Sie haben sich dafür entschieden, zukünftig Angebote von Valentino Beauty zu erhalten. Wenn Sie es wünschen, können Sie den Newsletter jederzeit wieder abbestellen.",
  },

  welcome: {
    select_country: "Wähle Dein Land*",
    select_country_placeholder: "Wähle Dein Land",
    select_country_error: "Wähle Dein Land",
    select_language: "Wähle Deine Sprache*",
    select_language_placeholder: "Wähle Deine Sprache",
    select_language_error: "Wähle Deine Sprache",
    cta_code: "Ich habe einen einmaligen code",
    cta_no_code: "Ich habe keinen einmaligen code",
    not_available: "Es tut uns leid, aber der <b>Valentino Beauty Dreams Club</b> ist in dem ausgewählten Land leider nicht verfügbar. Wir laden Dich ein, uns auf Instagram zu folgen, um die neusten Updates unter <b class=\"cursor-pointer\"><a href=\"https://www.instagram.com/valentino.beauty/\" target=\"_blank\">@valentino.beauty</a></b> zu entdecken.",
    go_back: "Ich ändere das Land",
  },

  register: {
    title_with_code: "DIES IST DER PERFEKTE ORT UM DEINE EIGENEN SCHÖNHEITSTRÄUME/BEAUTY DREAMS ZU ERSCHAFFEN",
    subtitle_with_code: `Bei Valentino Beauty feiern wir Deine Treue. Du kannst den einmaligen Code auf Deinem Produkt beim <b>Valentino Beauty Dreams Club</b> in folgenden Ländern einlösen.<br><br> Auf <a class='underline' target="_blank" href="https://www.valentino-beauty.ch/de_CH/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club" class='underline'>valentino-beauty.ch</a> erfährst Du mehr über unsere neuesten Produkte und exklusiven Serviceangebote.`,
    title: "DIES IST DER PERFEKTE ORT UM DEINE EIGENEN SCHÖNHEITSTRÄUME/BEAUTY DREAMS ZU ERSCHAFFEN.",
    subtitle: "Registriere Dich um exklusive Angebote und Services zu erhalten.",
    code_exaplanation_title: "Wo finde ich meinen Code ?",
    code_explanation_description: "Dein persönlicher Code befindet sich in der Produktverpackung.",
    not_mandatory: "*Pflichtfelder",

    form: {
      names: {
        gender: "Geschlecht",
        firstname: "Vorname",
        lastname: "Nachname",
        birthday: "Geburtsdatum",
        email: "E-Mail-Adresse",
        address: "Postanschrift",
        zipcode: "Postleitzahl",
        city: "Stadt",
      },

      unique_code: "Trage Deinen einmaligen Code ein*",
      gender: "Wähle Dein Geschlecht",
      civilities: { 'Herr*': 1, 'Frau*': 2, 'Neutral*': 3 },
      first_name: "Vorname*",
      last_name: "Nachname*",
      birthday: "Geburtsdatum: TT/MM/JJJJ*",
      email: "E-Mail Adresse*",
      address: "Anschrift*",
      zipcode: "Postleitzahl*",
      city: "Stadt*",
      terms: "Ich akzeptiere die Allgemeinen Geschäftsbedingungen*",
      iOptin_2_label: "Mit der Registrierung erkläre ich mich einverstanden, E-Mails und SMS von Valentino Beauty zu erhalten.",
      submit: "Registrieren",
    },
  },

  diffusion: {
    title: "VIELEN DANK!",
    subtitle_with_code: "Willkomen im <b>Valentino Beauty Dreams Club</b>.<br />In Deinem Postfach wartet eine Überraschung auf Dich.",
    subtitle_email_already_exist: "Sie sind bereits Mitglied des <b>Valentino Beauty Dreams Clubs</b>. Wir haben Ihren einmalig vergebenen Code berücksichtigt.",
    subtitle_bis_with_code: "Auf <a href=\"https://www.valentino-beauty.ch/de_CH/?utm_source=numberly&utm_medium=traffic&utm_campaign=dreams-club\" target=\"_blank\"><b>valentino-beauty.ch</b></a> erfährst Du mehr über unsere neuesten Produkte und exklusiven Serviceangebote.",
    subtitle: "Du bist registriert.",
    subtitle_bis: "Folge uns auf Instagram, um die aktuellsten Neuigkeiten zu entdecken auf <b class=\"cursor-pointer\">@valentino.beauty</b>.",
  },

  footer: {
    terms: "Geschäftsbedingungen",
    mentions: "Mentions",
    cookies: "Cookie use policy",
    contact: {
      button: "Kontaktiere uns",
      email: "test@numberly.com",
      subject: "OP TITLE"
    },
    charteFb: "Privacy Policy",
    realisation: {
      button: "Urheberrecht",
      address: "57-61 Mortimer Street - London W1W 8HS - UK<br />www.1000mercis.co.uk",
      email: "Email",
      contact: "Bei Fragen stehen wir gerne zur Verfügung. ",
      mailto: "katherin@numberly.com",
      numberly: "http://www.numberly.com"
    }
  },
  modals: {
    share_link: {
      title: "Share the link with your friends",
      subtitle: "by copying the URL",
      action: "COPY",
      success: "Link copied successfuly"
    },
    summary: {
      title: "Your invited friends",
      relance: "Resend",
      registered: "Registered",
      button: "See my registered friends"
    },
    share_email: {
      title: "Invite your friends",
      subtitle: "via their email address",
      email: "Email {index}",
      alreadyUsed: "The email address has already been used.",
      mentions: "The email addresses given here will alllow us to send invitations to your friends to also take part in the quiz. These emails will not be retained nor used after the game.",
      submit: "Send my invites",
      success: "The email has been sent.|The emails have been sent."
    }
  },

  // https://docs.google.com/spreadsheets/d/1Ym_O37QBF3-n0Ub1FlDzkIwe3CtSAhMDlFlia18pYLU/edit#gid=335035831
  errors: {
    unique_code_burned: 'Dieser Code wurde bereits registriert.',
    unique_code_invalid: 'Dieser Code scheint nicht korrekt zu sein.',
    unique_code_required: 'Bitte gib Deinen persönlichen Code ein',
    required: 'Bitte gib Deinen {value} ein',
    invalid: 'Dein {value} scheint falsch zu sein',
    required_checkbox: 'Das Feld Begriffe ist erforderlich',

    gender: {
      required: 'Bitte gib Dein Geschlecht an',
    },

    firstname: {
      required: 'Bitte gib Deinen Vornamen ein',
      invalid: 'Dein Vorname scheint falsch zu sein',
    },

    lastname: {
      required: 'Bitte gib Deinen Nachnamen ein',
      invalid: 'Dein Nachname scheint falsch zu sein',
    },

    birthday: {
      required: 'Bitte gib Dein Geburtsdatum ein',
      invalid: "Dein Geburtsdatum ist ungültig",
      too_young: "Du musst mindestens {value2} Jahre alt sein, um Dich zu registrieren",
      too_old: "Dein Geburtsdatum ist ungültig",
    },

    email: {
      required: 'Bitte gib Deine E-Mail-Adresse ein',
      invalid: "Bitte gib eine gültige E-Mail-Adresse ein",
      alreadyUsed: "Du bist bereits Mitglied beim <b>Valentino Beauty Dreams Club</b>.",
      alreadyRegistered: "Du bist bereits Mitglied beim <b>Valentino Beauty Dreams Club</b>.",
      noEmail: "Enter at least one email",
      duplicateEmail: "At least 2 of the emails you entered are identical",
      ownEmail: "You cannot use your own email"
    },

    address: {
      required: 'Bitte gib Deine Postanschrift ein',
      invalid: 'Bitte gib eine gültige Postanschrift ein',
    },

    zipcode: {
      required: 'Bitte gib Deine Postleitzahl ein',
      invalid: 'Bitte gib eine gültige Postleitzahl ein',
      min: 'Bitte gib eine gültige Postleitzahl ein'
    },

    city: {
      required: 'Bitte gib Deine Stadt ein',
      invalid: 'Deine Stadt ist ungültig',
    },
  }
}