<template>
  <div class="site-footer w-full mt-8 py-4">
    <div class="site-footer-container relative flex items-center justify-between w-full mx-auto text-sm">
      <!-- CONTACT -->
      <template v-if="$te('footer.contact.button')">
        <div>
          <a
            class="cursor-pointer"
            data-cy="contact-cta"
            href="https://www.valentino-beauty.com/int/customer-service/contact-us/customer-service-contact-us.html?geo=false"
            target="_blank"
          >
            {{ $t('footer.contact.button') }}
          </a>
          <!-- <a
            class="cursor-pointer"
            data-cy="contact-cta"
            :href="`mailto:${$t('contact.email')}?subject=${$t('contact.from')}`"
            target="_blank"
          >
            {{ $t('footer.contact.button') }}
          </a> -->
        </div>
      </template>

      <span class="dot">&nbsp;|&nbsp;</span>

      <!-- TERMS -->
      <template v-if="$te('footer.terms')">
        <div>
          <a
            class="cursor-pointer"
            data-cy="terms-open-cta"
            @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })"
          >
            {{ $t('footer.terms') }}
          </a>
        </div>
      </template>

      <span v-if="externalCookieTrackingHtml" class="dot">&nbsp;|&nbsp;</span>

      <!-- TERMS -->
      <template v-if="externalCookieTrackingHtml && externalCookieTrackingHtml.cookiesSettings">
        <div v-html="externalCookieTrackingHtml.cookiesSettings"></div>
      </template>

      <span class="dot">&nbsp;|&nbsp;</span>
      <!-- TERMS -->
      <template v-if="$te('legal.label')">
        <div>
          <a
            class="cursor-pointer"
            data-cy="legal-open-cta"
            target="_blank"
            :href="$t('legal.url')"
          >
            {{ $t('legal.label') }}
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import cookielaw from '@/cookielaw';
import { isDevelopmentEnv, isStagingEnv } from '@/helpers';

export default {
  name: 'SiteFooter',

  methods: mapActions(['updateModalConfig']),

  computed: {
    externalCookieTracking() {
      const env = !isStagingEnv && !isDevelopmentEnv ? 'prod' : 'staging';

      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get('lang') ? urlParams.get('lang') : 'fr';

      const activeDecliName = this.$store.state.config.appConfig.activeDecli.name;
      let cookie = cookielaw.find((cookie) => cookie.decli === activeDecliName);

      if (cookie) {
        cookie = activeDecliName === 'ch' ? cookie[lang][env] : cookie[env];
  
        return cookie;
      }

      return null;
    },

    externalCookieTrackingHtml() {
      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get('lang') ? urlParams.get('lang') : 'fr';

      const activeDecliName = this.$store.state.config.appConfig.activeDecli.name;
      let cookie = cookielaw.find((cookie) => cookie.decli === activeDecliName);

      if (cookie) {
        cookie = activeDecliName === 'ch' ? cookie[lang] : cookie;
  
        return cookie.html ? cookie.html : null;
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.site-footer-container {
  max-width: 540px;
  z-index: 1;
}
</style>
