import { inIframe } from '@/helpers';
import store from '@/store';
import registerAuto from './registerAuto';
import relog from './relog';

/**
 * @param {import("vue-router").Route} to
 * @param {import("vue-router").Route} from
 * @param {(any) => void} next
 */
export default function(to, from, next) {
  const sUid = inIframe()
    ? store.getters['user/stateParams'].sUid
    : to.query.sUid || store.getters['user/stateParams'].sUid;

  return relog(sUid)
    .then((pageID) => {
      if (
        to.query.e &&
        Number(to.query.insc) === 1 &&
        store.getters['config/activeDecliConfig'].optinisation.active
      ) {
        registerAuto(to, next);
      } else if (to.name !== 'OptinisationPage') {
        const activeDecliRoutes = store.getters['config/activeDecliRoutes'];
        const routeToRelog = activeDecliRoutes.find((route) => route.meta && route.meta.pageId === pageID);
        console.log('ROUTE', routeToRelog.name)

        // Remove the sUid before copy/paste
        const query = JSON.parse(JSON.stringify(to.query))
        delete query.sUid

        next({
          name: routeToRelog.name,
          query,
        });
      } else {
        next();
      }
    })
    .catch(console.log);
}