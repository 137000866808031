import Vue from 'vue';

/**
 * @param {import('vue-router').Route} to
 * @param {(any) => void} next
 */
export default (to, next) => {
  Vue.$requestAPI.pageView(1)
    .then(() => {
      const data = {
        sEmail: to.query.e,
      };

      return Vue.$requestAPI.registerOrRelog(data);
    })
    .then(() => Vue.$requestAPI.getSessionCurrent(true))
    .then(() => {
      next();
    })
    .catch((error) => {
      // Redirections will be managed by events dispatched
      next(false)
      console.log(error)
    });
};
