<template>
  <svg class="spin" width="15" height="15" viewBox="0 0 496 489" xml:space="preserve">
    <path class="shape" d="M280,24.056 L280,40.715 C280,51.519 287.281,60.874 297.686,63.781 C375.204,85.434 432,156.518 432,241 C432,342.689 349.705,425 248,425 C146.311,425 64,342.705 64,241 C64,156.53 120.786,85.436 198.312,63.781 C208.719,60.874 216,51.517 216,40.712 L216,24.064 C216,8.355 201.166,-3.089 185.954,0.83 C78.603,28.482 -0.606,126.206 -0.000487812053,242.332 C0.723,379.384 111.48,489.288 248.534,488.999569 C385.255,488.711 496,377.788 496,241 C496,125.367 416.86,28.221 309.789,0.764 C294.678,-3.111 280,8.456 280,24.056 Z"/>
  </svg>
</template>

<script>
export default {
  name: 'LoadingIcon'
}
</script>

<style lang="scss" scoped>
.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}
@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
</style>