const assets = require('../../ui.config.json')

const design = {}

function checkPropsRequired (object, requiredList, optionalList = []) {
    let valid = Object.keys(object).filter(prop => requiredList.indexOf(prop) > -1).length === requiredList.length
    optionalList = optionalList.concat(requiredList)
    let validOptional = Object.keys(object).filter(prop => optionalList.indexOf(prop) === -1).length === 0

    if (!valid) {
        throw new Error('ui.config.json invalid: missing props ' +  requiredList.join(','))
    }
    if (!validOptional) {
        throw new Error('ui.config.json invalid: available props:' + optionalList.join(','))
    }
    return valid
}



function getValue (object, property, defaultValue, { required = false, valueList = [] }) {
    const value = object[property]

    if (required && value === undefined) {
        throw new Error('ui.config.json invalid: value undefined')
    }
    if (valueList.length > 0 && valueList.indexOf(value) < 0) {
        throw new Error('ui.config.json invalid')
    }
    return (value === undefined || value === '')  && defaultValue ? defaultValue : value
}

function reOrderColor(cb) {
    design.colors = {}
    const colorStyles = assets.colors

    if (checkPropsRequired(colorStyles, ['error', 'success', 'primary', 'secondary'], ['tertiary', 'quaternary', 'quinary'])) {
        Object.keys(assets.colors).forEach(colorName => {
            design.colors[colorName] = assets.colors[colorName]
        })
    }
    cb()
}

function reOrderStyle() {
    design.characterStyles = {}
    const characterStyles = assets.characterStyles
    if (checkPropsRequired(characterStyles, ['sm', 'md', 'lg', 'xl'], ['xs', 'xxl'])) {
        Object.keys(characterStyles).forEach(characterName => {
            if (checkPropsRequired(characterStyles[characterName], ['fontSize', 'fontFamily'])) {
                design.characterStyles[characterName] = {}
                design.characterStyles[characterName].fontSize = getValue(characterStyles[characterName], 'fontSize', '10px', { required: true, valueList: [] })
                design.characterStyles[characterName].fontFamily = getValue(characterStyles[characterName], 'fontFamily', '10px', { required: true, valueList: [] })
            }
        })
    }
}
function reOrderProps() {
    const panoply = assets.panoply
    design.panoply = {}

    if (checkPropsRequired(panoply, ['inputs', 'cta'])) {
        Object.keys(panoply).forEach(propsName => {
            switch(propsName) {
                case 'inputs':
                    design.panoply[propsName] = {}
                    design.panoply[propsName].designType = getValue(panoply[propsName], 'designType', 'line', { required: true, valueList: ['line', 'box'] })
                break
                case 'cta': 
                design.panoply[propsName] = {}
                design.panoply[propsName].designType = getValue(panoply[propsName], 'designType', 'line', { required: true, valueList: ['line', 'box', 'ghost'] })
                design.panoply[propsName].rounded = getValue(panoply[propsName], 'rounded', 'false', { required: true, valueList: ['true', 'false'] }) === 'false' ? false : true
                break
                default:
                    throw new Error('ui.config.json invalid: Props panoply invalid')
            }
        })
    }
}

if (checkPropsRequired(assets, ['version', 'panoply', 'characterStyles', 'colors'])) {
    reOrderStyle()
    reOrderColor(reOrderProps)
}

module.exports = design
