import translations from '../../translations/ch_de';

export default {
  ...translations,

  legal: {
    label: 'Erwähnungen rechtliches',
    url: 'https://www.valentino-beauty.ch/de_CH/kundenservice/terms-bedingungen/customer-service-terms-and-conditions.html'
  },

  contact: {
    email: 'contact@welcome.valentino-beauty.ch',
    from: 'Valentino Beauty',
  },

  register_optin: {
    no_code: {
      iOptin_2_label: `
      Ich bestätige hiermit mindestens 18 Jahre zu sein und willige ein, dass Valentino Beauty, meine E-Mailadresse für Informationen zu exklusiven Angeboten, zu einen früheren Zugang zu neuen Produkten, individuellen Empfehlungen verwendet.`,
    },

    code: {
      terms: `Ich akzeptiere die <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Allgemeinen Treueprogrammbedingungen</a> von Valentino Beauty.*`,
      iOptin_5_label: `Ich möchte am Valentino Beauty Dreams Club-Treueprogramm teilnehmen. Die in diesem Formular gesammelten Informationen werden von L'Oréal im Namen der Marke Valentino Beauty für die Verwaltung und Administration Ihres Treuekontos sowie für statistische und analytische Zwecke verarbeitet.`,
      iOptin_2_label: `Ich bestätige hiermit mindestens 18 Jahre zu sein und willige ein, dass Valentino Beauty, meine E-Mailadresse für Informationen zu exklusiven Angeboten, zu einen früheren Zugang zu neuen Produkten, individuellen Empfehlungen verwendet.`,
    },
  },
};
