import Vue from 'vue';
import { email, required, min, length } from 'vee-validate/dist/rules';
import { ValidationObserver, localize, extend } from 'vee-validate';
import { rules as nInputsRules } from '@team-uep/n-inputs';

import i18n from './i18n';
import { getDecli } from '@/helpers';
import appConfig from '@/declis';

const localeName = getDecli(appConfig.declis).name;

let veevalidateLocaleName;

// Fix incorrect language dictionnary for vee-validate, as it doesn't have
// dictionnaries named "us" or "ch".
if (localeName === 'us') {
  veevalidateLocaleName = 'en';
} else if (localeName === 'ch') {
  veevalidateLocaleName = 'de';
} else if (localeName === 'be') {
  veevalidateLocaleName = 'fr';
} else if (localeName === 'at') {
  veevalidateLocaleName = 'de';
} else {
  veevalidateLocaleName = localeName;
}

/**
 * Override rule message with an i18n translation key.
 * 
 * @param {Object} rule 
 * @param {String} translateKey 
 */
const editRuleMsg = (rule, translateKey) => {
  return Object.assign({}, rule,
    {
      message: (value, params) => {
        // Convert params names to value, value2, value3...
        const values = { value }
        const paramsNames = (rule.params || []).map(data => data.name || data)
        paramsNames.forEach((name, i) => values['value' + (i + 2)] = params[name])

        return i18n.t(translateKey, values)
      },
    },
  );
};

// Override vee-validate rules list.
const rules = {
  length,
  
  email: editRuleMsg(email, 'errors.email.invalid'),
  
  required: editRuleMsg(required, 'errors.required'),
  requiredGender: editRuleMsg(required, 'errors.gender.required'),
  requiredFirstname: editRuleMsg(required, 'errors.firstname.required'),
  requiredLastname: editRuleMsg(required, 'errors.lastname.required'),
  requiredBirthday: editRuleMsg(required, 'errors.birthday.required'),
  requiredEmail: editRuleMsg(required, 'errors.email.required'),
  requiredAddress: editRuleMsg(required, 'errors.address.required'),
  requiredZipcode: editRuleMsg(required, 'errors.zipcode.required'),
  min: editRuleMsg(min, 'errors.zipcode.min'),
  requiredCity: editRuleMsg(required, 'errors.city.required'),
  requiredCheckbox: editRuleMsg(nInputsRules.checkboxRequired, 'errors.required_checkbox'),
  
  ageMin: editRuleMsg(nInputsRules.ageMin, 'errors.birthday.too_young'),
  ageMax: editRuleMsg(nInputsRules.ageMax, 'errors.birthday.too_old'),
  birthday: editRuleMsg(nInputsRules.date, 'errors.birthday.invalid'),
  
  nameLatin: editRuleMsg(nInputsRules.nameLatin, 'errors.invalid'),
  nameLatinFirstname: editRuleMsg(nInputsRules.nameLatin, 'errors.firstname.invalid'),
  nameLatinLastname: editRuleMsg(nInputsRules.nameLatin, 'errors.lastname.invalid'),
  nameLatinAddress: editRuleMsg(nInputsRules.nameLatin, 'errors.address.invalid'),
  nameLatinZipcode: editRuleMsg(nInputsRules.nameLatin, 'errors.zipcode.invalid'),
  nameLatinCity: editRuleMsg(nInputsRules.nameLatin, 'errors.city.invalid'),

  radioRequired: editRuleMsg(nInputsRules.radioRequired, 'errors.required'),
  alreadyRegistered: editRuleMsg({ validate: (_, [state]) => state !== 'true' }, 'errors.email.alreadyRegistered'),
  phoneMobileFr: editRuleMsg({ validate: (value) => value.match(/^0[6-7]( \d{2}){4}$/) }, 'errors.invalid'),
  addressIsValid: editRuleMsg({ validate: (value) => !value.match(/[@]/) }, 'errors.invalid'),
};

// Make vee-validate trigger all rules.
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.component('ValidationObserver', ValidationObserver);

import(`vee-validate/dist/locale/${veevalidateLocaleName}.json`).then(module => {
  localize(localeName, module.default);
});
