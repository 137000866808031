import translations from '../translations/at';

export default {
  ...translations,

  legal: {
    label: 'Erwähnungen rechtliches',
    url: 'https://www.valentino-beauty.at/kundenservice/nutzungsbedingungen/customer-service-terms-and-conditions.html'
  },

  contact: {
    email: 'contact@welcome.valentino-beauty.at',
    from: 'Valentino Beauty',
  },

  register_optin: {
    no_code: {
      iOptin_2_label: `Hiermit willige ich ein, dass Valentino Beauty, einer Marke der L’Oréal S.A., („Valentino Beauty“) meine personenbezogenen Daten dafür verwendet, um mir individuelle Werbemittel per E-Mail zuzusenden. Ich bestätige, dass ich mindestens 18 Jahre alt bin.`,
    },

    code: {
      terms: `Die <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Teilnahmebedingungen</a> des Treueprogramms habe ich zur Kenntnis genommen und akzeptiert.*`,
      iOptin_5_label: `Ja, ich möchte am Treueprogramm von Valentino Beauty teilnehmen.`,
      iOptin_2_label: `Hiermit willige ich ein, dass Valentino Beauty, einer Marke der L’Oréal S.A., („Valentino Beauty“) meine personenbezogenen Daten dafür verwendet, um mir individuelle Werbemittel per E-Mail zuzusenden. Ich bestätige, dass ich mindestens 18 Jahre alt bin.`,
    },
  },
};
