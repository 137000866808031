import translations from '../translations/en';

export default {
  ...translations,

  legal: {
    label: 'Legal Notices',
    url: 'https://www.valentino-beauty.co.uk/customer-service/terms-conditions/customer-service-terms-and-conditions.html'
  },

  contact: {
    email: 'contact@welcome.valentino-beauty.co.uk',
    from: 'Valentino Beauty',
  },

  register_optin: {
    no_code: {
      iOptin_2_label: `I'd love to receive personalised emails containing offers, news and a chance to get samples and win prizes from Valentino! Remember, you need to be at least 13 to sign up to marketing.`,
    },

    code: {
      terms: `I accept the <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Terms and Conditions</a>.*`,
      iOptin_5_label: `I'd love to join the Valentino Beauty Dreams Club loyalty program. Valentino will use your personal data to manage your loyalty account, for analytics and market research, and where you have opted in, to send you personalised updates.`,
      iOptin_2_label: `I'd love to receive personalised emails containing offers, news and a chance to get samples and win prizes from Valentino! Remember, you need to be at least 13 to sign up to marketing.`,
    },
  },
};
