<template>
  <div class="site-header">
  </div>
</template>

<script>
export default {
  name: 'SiteHeader'
}
</script>

<style lang="scss" scoped></style>
