<!-- COOKIES BANNER -->
<template>
  <div
    v-if="unabledCookie && cookiesBannerStatus && ($route.name === 'register' || $route.name === 'welcome')"
    class="z-10 fixed flex flex-row items-center justify-center w-full p-2 text-center bg-white"
    data-cy="cookie-banner"
    @click="hideCookiesBanner"
  >
    <div class="max-w-7xl mx-auto">
      <dynamic
        name="cookie-banner"
        :template="$t('cookies.banner')"
        :data="{ updateModalConfig, hideCookiesBanner }"
      />
    </div>

    <a
      class="text-xl cursor-pointer close-btn"
      data-cy="close-cookie-banner"
      @click="hideCookiesBanner"
    >
      &times;
    </a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Cookies',

  data () {
    return {
      unabledCookie: false,
      cookiesBannerStatus: true,
    };
  },

  computed: mapGetters('config', ['appConfig']),

  methods: {
    ...mapActions(['updateModalConfig']),

    hideCookiesBanner () {
      this.$cookie.set(`${this.appConfig.baseName}CNIL`, true);
      this.cookiesBannerStatus = false;
    },
  },

  mounted () {
    this.cookiesBannerStatus = !this.$cookie.get(`${this.appConfig.baseName}CNIL`);
  },
};
</script>
