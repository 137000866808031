import translations from '../translations/it';

export default {
  ...translations,

  legal: {
    label: 'Le note Legali',
    url: 'https://www.valentino-beauty.it/assistenza-cliente/termini-e-condizioni/customer-service-terms-and-conditions.html'
  },

  contact: {
    email: 'contact@welcome.valentino-beauty.it',
    from: 'Valentino Beauty',
  },

  register_optin: {
    no_code: {
      iOptin_2_label: `Lo accetto, L'Oréal S.A., in relazione ai prodotti e servizi di Valentino Beauty, tratterà i tuoi dati personali per inviarti comunicazioni contenenti offerte e novità. Devi avere almeno 16 anni per ricevere le nostre comunicazioni.`,
    },

    code: {
      terms: `Accetto le <a class="underline cursor-pointer" data-cy="open-cookie-banner" @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })">Condizioni Generali</a> del programma fedeltà Valentino Beauty Dreams Club.*`,
      iOptin_5_label: `Si, vorrei partecipare al programma feldeltà Valentino Beauty. L’Oréal S.A., in relazione al marchio Valentino Beauty, tratterà i tuoi dati per consentirti di prendere parte all'iniziativa Valentino Beauty Dreams Club.`,
      iOptin_2_label: `Lo accetto, L'Oréal S.A., in relazione ai prodotti e servizi di Valentino Beauty, tratterà i tuoi dati personali per inviarti comunicazioni contenenti offerte e novità. Devi avere almeno 16 anni per ricevere le nostre comunicazioni.`,
      iOptin_6_label: `Acconsento alla creazione di profili da parte di L’Oréal S.A. secondo le Sue preferenze, abitudini, interessi, comportamenti al fine di ricevere comunicazioni commerciali personalizzate.`,
    },
  },
};
